import React, { useContext, useState } from 'react';
import axios from 'axios';
import { Button, Form, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from 'styled-components';
import Navbar from '../common/NavBar';
import { myContext } from '../../context/ContextProvider';
import languageHome from '../../locales/home/language-home';

const NewsletterUnsubscribe = () => {
    const { Language } = useContext(myContext);
    const [email, setEmail] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('http://localhost:5002/api/newsletter/unsubscribe', { email });
            alert(response.data);
        } catch (error) {
            alert(error.response.data);
        }
    };

    return (
        <>
        <Navbar/>
        <Container className="my-4">
            <h2>{languageHome[Language].newsletter.unsub}</h2>
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                    <Form.Control 
                        type="email" 
                        placeholder={languageHome[Language].newsletter.emailunsub} 
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)} 
                    />
                </Form.Group>
                <Button variant="danger" type="submit">
                {languageHome[Language].newsletter.buttonunsub}
                </Button>
            </Form>
        </Container>
        </>
    );
};

export default NewsletterUnsubscribe;

const UnsubscribeContainer = styled(Container)`
    
`;
