import React, { useContext } from 'react'
import styled from 'styled-components'

import img from '../../img/img';
import Logo from './Logo';
import GoldButton from '../common/GoldButton';
import languageHome from '../../locales/home/language-home';
import { myContext } from '../../context/ContextProvider';
import SslSecureBadge from '../common/SslSecureBadge';

export default function Banner({ title }) {
    const { Language } = useContext(myContext);
    return (
        <>
            <BannerSection className='hero-section'>
                <div className='background'></div>
                <div className='banner-text align-center '>
                    <p className='welcome font-face-lora'>
                        {languageHome[Language].home.welcome.text1}
                    </p>
                    <Logo />
                    <p style={{width:"600px"}} className='banner-copy font-face-lora'>
                        {languageHome[Language].home.welcome.text2}
                    </p>
                    <GoldButton
                        text={languageHome[Language].home.button.text}
                        link={languageHome[Language].home.button.link}
                    />
                </div>
                <SslSecureBadge/>
            </BannerSection>
        </>
    )
}

const BannerSection = styled.section`
    position: relative;
    width: 100%;
    height: 600px;
    .background {
        height: 100%;
        background-image: url(${img.banner_chair});
        background-size: cover;
        background-position: center 40%;
        filter: opacity(60%);
    }
    .banner-text{
        width: 100%;
        position: absolute;
        top: 1rem;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
        .welcome {
            font-size: 1.7rem;
            letter-spacing: 3px;
            /* font-weight: bold; */
            font-weight: bold;
        }
        .banner-copy {
            margin-top: 1rem;
            font-size: 1.7rem;
            letter-spacing: 3px;    
            font-weight: bold;
        }
        
    }
    /* @media screen and (min-width: 280px) and (max-width: 1079px) {
      
      height: 600px;
    } 
    @media screen and (min-width: 1925px) {
      
      height: 600px;
    }  */
`;