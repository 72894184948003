import React from 'react'
import { NavLink } from 'react-router-dom';
import { GiWhiteBook } from 'react-icons/gi';
import { BsBook, BsFiletypePdf } from 'react-icons/bs';
import { FcKindle } from "react-icons/fc"
import styled from 'styled-components'

export default function FormatButton({ arr }) {
    return (
        <>
            <FormatButtonContainer>
                {
                    arr.map((item, index) => (
                        item.type !== "" && (
                            // <div key={index}>
                            // <NavLink key={index} className="nav-link">
                            <a className="nav-link" href={item.link} target="_blank" rel="noreferrer" key={index}>
                                <div className='button-formats' >
                                    <div className='icon'>
                                        {
                                            item.icon === "pdf" ?
                                                <BsFiletypePdf />
                                                :
                                                item.icon === "hardcover" ?
                                                    <GiWhiteBook />
                                                    :
                                                    item.icon === "kindle" ?
                                                        <FcKindle />
                                                        :
                                                        item.icon === "paperback" ?
                                                            <BsBook />
                                                            :
                                                            null
                                        }
                                    </div>
                                    <div className='text-container'>
                                        <p className='button-text font-face-GlacialIndifference'>{item.type}</p>
                                        <p className='button-text font-face-GlacialIndifference'>{item.price}</p>
                                    </div>
                                </div>
                                {/* </NavLink> */}
                            </a>
                            // </div>
                        )
                    ))
                }
            </FormatButtonContainer>
        </>
    )
}

const FormatButtonContainer = styled.div`
        display: flex;
        text-decoration: none;
        gap: 1rem;
        .nav-link{
            text-decoration: none;
            .button-formats{
                border-radius: 10px;
                border: 1px solid #b3b3b3;      
                height: 60px;
                min-width: 14rem;
                display: flex;
                justify-content: center;
                align-items: center;
                /* position: relative;
                z-index: 0;
                overflow: hidden; */
                gap: 1rem;
                cursor: pointer;
                color: #303030;
                letter-spacing: 4px;
                text-transform: uppercase;
                font-size: 1rem;
                font-weight: 900;
                transition: 0.3s ease-in-out;
                box-shadow: inset 0px 0px 7px 3px #fff, 0px 12px 29px -18px #AA8658, 0px 2px 50px 15px rgba(0,0,0,0);
                /* text-shadow: 2px 2px 0 #444444, -2px 2px 0 #cfcccc; */
                .icon{
                    /* border: 2px solid #ff0000;       */
                    font-size: 1.5rem;
                    text-align: center;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    margin-left: 1rem;
                }
                .text-container{
                    /* border: #0026ff solid 2px; */
                    .button-text{
                        /* border: #15ff00 solid 2px; */
                        font-size: .8rem;
                        /* padding: .4rem; */
                        margin: 0;
                    }
                }
            }
        }
    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
`;
