import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import img from '../../img/img';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export default function CarrouselBoot({ carousel }) {

    return (
        <>
            <CustomCarouselItem>
                <div className='car'>
                    <div className='wraper'>
                        <Carousel fade>
                            {carousel.map((book, index) => (
                                <Carousel.Item key={index}>
                                    <Link to={book.link} >
                                        <img src={img[book.image]} alt="" className="d-block w-100" 
                                        />
                                    </Link>
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </div>
                </div>
            </CustomCarouselItem>

            <CustomCarouselItemMob>
                <div className='car'>
                    <div className='wraper'>
                        <Carousel fade>
                            {carousel.map((book, index) => (
                                <Carousel.Item key={index}>
                                    <Link to={book.link} >
                                        <img src={img[book.imagemob]} alt="" className="d-block w-95" />
                                    </Link>
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </div >
                </div >
            </CustomCarouselItemMob>
        </>
    );
}

const CustomCarouselItem = styled.div`
display: flex;
justify-content: center;
    .car{
        margin-top: 2rem;
        display: flex;
        justify-content: center;
        width: 100%;
        .wraper{
            -webkit-box-shadow: inset -1px 3px 8px 5px #dbdbdb, 2px 5px 16px 0px #ebebeb, 0px 2px 50px 15px rgba(0,0,0,0); 
            box-shadow: inset -1px 3px 8px 5px #edeeef, 0px 12px 29px -10px #AA8658, 0px 2px 50px 15px rgba(0,0,0,0);
            width: 1080px;
        }
    }
    @media (max-width: 720px) {
        /* display: none; */
    }
`;

const CustomCarouselItemMob = styled.div`
    display: none;
    @media (max-width: 720px) {
        /* display: flex; */
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .car{
            margin-top: 5rem;
            width: 400px ;
            .wraper{
                -webkit-box-shadow: inset -1px 3px 8px 5px #dbdbdb, 2px 5px 16px 0px #ebebeb, 0px 2px 50px 15px rgba(0,0,0,0); 
                box-shadow: inset -1px 3px 8px 5px #edeeef, 0px 12px 29px -10px #AA8658, 0px 2px 50px 15px rgba(0,0,0,0);
            }
        }
        
    }
`;
