import React, { useContext } from 'react'
import styled from 'styled-components'
import BannerDinamic from './BannerDinamic';
import Navbar from '../common/NavBar';
import languageHome from '../../locales/home/language-home';
import { myContext } from '../../context/ContextProvider';
import booksExporter from '../../locales/books/books-exporter';
import BooksContainer from '../common/BooksContainer';
import Footer from '../common/Footer';
import ScrollToTop from '../common/ScrollToTop';

export default function Collections() {

    const { Language } = useContext(myContext);

    return (
        <>
            <CollectionsComponent>
                <ScrollToTop/>
                <Navbar navbar={languageHome[Language]?.navbar} />
                <BannerDinamic title={languageHome[Language]?.collection?.section_title} />
                <BooksContainer
                    sectionTitle={languageHome[Language]?.collection?.collection_title?.esoteric}
                    books={booksExporter[Language]?.esoteric}
                />
                <BooksContainer
                    sectionTitle={languageHome[Language]?.collection?.collection_title?.scifi}
                    books={booksExporter[Language]?.['science-fiction']}
                />
                <Footer footer={languageHome[Language]?.footer} />
            </CollectionsComponent>
        </>
    )
}

const CollectionsComponent = styled.div`
    background-color: #EBE7E3;
`;