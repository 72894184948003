import React, { createContext, useState, useEffect } from 'react';
// import { app } from '../firebase/firebase';
// import { getAnalytics } from "firebase/analytics";

export const myContext = createContext();

const ContextProvider = ({ children }) => {
    
    // const analytics = getAnalytics(app);
    
    const [Name, setName] = useState("");
    const [error, setError] = useState('');

    
    const [Language, setLanguage] = useState("en");
    
    const [ShowModal, setShowModal] = useState(false);
    const tempoParaAbrirModal = 10000;
    
    useEffect(() => {
        
        const timer = setTimeout(() => {
            setShowModal(true);
        }, tempoParaAbrirModal);
        
        return () => clearTimeout(timer);
    }, []);
    
    const idioma = navigator.language;
    
    useEffect(() => {
        if (idioma.startsWith("pt")) {
            setLanguage("pt");
        } else if (idioma.startsWith("en")) {
            setLanguage("en");
        } else if (idioma.startsWith("de")) {
            setLanguage("de");
        } else {
            setLanguage("en");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idioma]);

    const [ShowBookPreview, setShowBookPreview] = useState(false);
    const [pdfLink, setPdfLink] = useState("");

    return (
        <myContext.Provider
            value={{
                // analytics,
                Language,
                setLanguage,
                error,
                setError,
                Name,
                setName,
                ShowModal,
                setShowModal,
                ShowBookPreview, setShowBookPreview,
                pdfLink, setPdfLink
            }}
        >
            {children}
        </myContext.Provider>
    );
};

export default ContextProvider;
