import React from 'react'
import styled from 'styled-components'

export default function LogoNav() {
    return (
        <LogoComponent>
            
                <div className='banner-title'>
                    <span className='font-face-allura'>a</span>
                    <span className='span-e font-face-allura'>&</span>
                    <span className='font-face-allura'>t</span>
                </div>
                <p className='span-tales font-face-lora '>tales</p>
            
        </LogoComponent>
    )
}

const LogoComponent = styled.div`
    width: 100%;
    /* position: absolute; */
    /* top: 7rem; */
    text-align: center;
    display: flex;
    align-items: end;
    justify-content: center;
    .banner-title {
        /* border: #000000 solid 1px; */
        /* margin-left: -3rem; */
        /* margin-top: 2rem; */
        padding: 0;
            span{
                /* letter-spacing: 6px; */
                text-transform: uppercase;
                color: #a68e45;
                font-size: 3.5rem;
                font-weight: bold;
                text-shadow: 2px 3px 8px rgba(0,0,0,1);
            }
            .span-e{
                text-shadow: 2px 3px 8px rgba(0,0,0,0.66);
                /* letter-spacing: 9px; */
                color: #2f2323;
                font-size: 2rem;
            }
        
    }
    .span-tales{
        /* position: absolute; */
        font-weight: bold;
        letter-spacing: 16px;
        text-transform: uppercase;
        color: #2f2323;
        font-size: 1.2rem;
        margin-left: 2rem;
    }  
`;
