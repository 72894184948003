import bookspt from "./books-pt.json"
import booksen from "./books-en.json"
import booksde from "./books-de.json"

const pt = bookspt
const en = booksen
const de = booksde

export default {
    pt,
    en,
    de
}