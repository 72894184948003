import React, { useContext } from 'react'
import styled from 'styled-components'
import { myContext } from '../../../context/ContextProvider';
import img from '../../../img/img';
import languageDll from '../../../locales/books/dll/language-dll';
import { Link } from 'react-router-dom';

export default function Modal() {
    const { Language, setShowModal } = useContext(myContext);
    return (
        <>
            <ModalComponent>
                <div className='modal-container'>
                    <button className='close' onClick={() => setShowModal(false)}>X</button>
                    <div className='copy'>
                        <h1 className='font-face-BodoniflfItalic'>
                            {languageDll[Language].modal.h1}
                        </h1>
                        <h2 className='font-face-prata'>
                            {languageDll[Language].modal.h2}
                        </h2>
                        <h3>
                            {languageDll[Language].modal.h3}
                        </h3>
                        <h4 className='font-face-GlacialIndifference'>
                            {languageDll[Language].modal.h4}
                        </h4>
                        <div className='book-mockup'>
                            <img src={img[`cover_pon_${Language}`]} alt="" />
                            <img src={img[`cover_pon_manual_${Language}`]} alt="" />
                            <img src={img[`cover_dll_${Language}`]} alt="" />
                        </div>
                        <h5 className='font-face-prata'>
                            {languageDll[Language].modal.h5}
                        </h5>
                    </div>
                    <Link to="/collections/deciphering-the-language-of-love_discount?category=esoteric" >
                        <button className='glow' type="button">
                            {languageDll[Language].modal.button}
                        </button>
                    </Link>
                </div>
            </ModalComponent>
        </>
    )
}

const ModalComponent = styled.div`
position: fixed;
margin-top: 1rem;
max-height: 800px;
max-width: 668px;
background-color: #fafafa;
box-shadow: 
inset -1px 3px 8px 5px #edeeef, 
0px 12px 29px -10px #AA8658,
0px 2px 50px 15px rgba(0,0,0,0);
border-radius: 10px;

.modal-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    background-image: url(${img.backgroundsale});
    &:before {
                content: '';
                /* background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000); */
                /* background: linear-gradient(45deg, #c5c537, #ffd900, #fffb00, #bee523, #bca515, #deaf16, #ebf708, #ffee00, #fff700); */
                background: linear-gradient(45deg, #C59237, #c5b53e, #b7c750, #a1bf64, #98cb73, #6cc293, #56a1c3, #4280c3, #C59237 );
                position: absolute;
                top: -2px;
                left:-2px;
                background-size: 400%;
                z-index: -1;
                filter: blur(5px);
                width: calc(100% + 4px);
                height: calc(100% + 4px);
                animation: glowing 20s linear infinite;
                opacity: 1;
                transition: opacity .3s ease-in-out;
                border-radius: 10px;
            }
    .close{
        position: absolute;
        top: 10px;
        right: 10px;
        /* margin: 2rem; */
        /* margin-top: 2rem; */
        /* width: 14rem; */
        font-family: serif;
        padding: .5rem;
        cursor: pointer;
        /* border-radius: 0.3rem; */
        border: 2px solid #000000;          
        color: #000000;
        /* letter-spacing: 4px; */
        text-transform: uppercase;
        /* background-color: white; */
        background: #FFFFFF;
        /* background: -moz-linear-gradient(top, #FFFFFF 0%, #FFFFFF 0%, #C59237 100%);
        background: -webkit-linear-gradient(top, #FFFFFF 0%, #FFFFFF 0%, #C59237 100%);
        background: linear-gradient(to bottom, #FFFFFF 0%, #FFFFFF 0%, #C59237 100%); */
        font-size: 1.3rem;
        font-weight: 900;
        text-transform: uppercase;
        transition: 0.3s ease-in-out;
        box-shadow: inset 0px 0px 7px 3px #fff, 0px 12px 29px -10px #AA8658, 0px 2px 50px 15px rgba(0,0,0,0);
    }
    .copy{
        /* border: 1px solid black; */
        text-align: center;
        /* padding: 2rem; */
        h1{
            text-transform: uppercase;
            text-decoration: underline;
            font-size: 2rem;
            padding: 1rem;
        }
        h2{
            padding: 1rem;
            font-size: 1.1rem;
        }
        h3{
            text-transform: uppercase;
            font-size: 3rem;
            padding: 1rem;
            color: #d7462d;
        }
        h4{
            font-size: 1.2rem;
            padding: 1rem;
            /* padding-left: 2rem;
            padding-right: 2rem; */
            letter-spacing: 2px;
            color: #402f13;
        }
        h5{
            /* border: 1px solid black; */
            text-transform: uppercase;
            font-size: 1rem;
            margin-top: 2rem;
            margin-bottom: 0;
            letter-spacing: 2px;
        }
        .book-mockup{
            width: 100%;
            height: auto;
            /* background-color: #c5913763;
            border-radius: 10px; */
            img{
                height: 200px;
                margin: 1rem;
            }
        }
    }
    
        /* margin: 2rem;
        margin-top: 2rem;
        width: 14rem;
        font-family: serif;
        padding: 1rem;
        cursor: pointer;
        border: 2px solid #AA8658;          
        color: #ffffff;
        letter-spacing: 4px;
        text-transform: uppercase;
        background: #FFFFFF;
        background: -moz-linear-gradient(top, #FFFFFF 0%, #FFFFFF 0%, #C59237 100%);
        background: -webkit-linear-gradient(top, #FFFFFF 0%, #FFFFFF 0%, #C59237 100%);
        background: linear-gradient(to bottom, #FFFFFF 0%, #FFFFFF 0%, #C59237 100%);
        font-size: 1.1rem;
        font-weight: 900;
        text-transform: uppercase;
        transition: 0.3s ease-in-out;
        box-shadow: inset 0px 0px 7px 3px #fff, 0px 12px 29px -10px #AA8658, 0px 2px 50px 15px rgba(0,0,0,0);
        &:hover {
            background-color: #ffffff;
        } */
    .glow {
            /* width: 220px;
            height: 50px; */
            /* border: none;
            outline: none; */
            
            
            position: relative;
            z-index: 0;
            
            margin: 1rem;
            /* margin-top: 2rem; */
            width: 14rem;
            font-family: serif;
            padding: 1rem;
            cursor: pointer;
            border: 2px solid #AA8658;          
            letter-spacing: 4px;
            text-transform: uppercase;
            color: #FFFFFF;
            text-shadow: 2px 2px 0 #444444;
            background: #FFFFFF;
            background: -moz-linear-gradient(top, #FFFFFF 0%, #FFFFFF 0%, #C59237 100%);
            background: -webkit-linear-gradient(top, #FFFFFF 0%, #FFFFFF 0%, #C59237 100%);
            background: linear-gradient(to bottom, #FFFFFF 0%, #FFFFFF 0%, #C59237 100%);
            font-size: 1.1rem;
            font-weight: 900;
            text-transform: uppercase;
            transition: 0.3s ease-in-out;
            box-shadow: inset 0px 0px 7px 3px #fff, 0px 12px 29px -10px #AA8658, 0px 2px 50px 15px rgba(0,0,0,0);
            &:before {
                content: '';
                /* background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000); */
                /* background: linear-gradient(45deg, #c5c537, #ffd900, #fffb00, #bee523, #bca515, #deaf16, #ebf708, #ffee00, #fff700); */
                background: linear-gradient(45deg, #C59237, #c5b53e, #b7c750, #a1bf64, #98cb73, #6cc293, #56a1c3, #42c39e, #C59237 );
                position: absolute;
                top: -2px;
                left:-2px;
                background-size: 400%;
                z-index: -1;
                filter: blur(5px);
                width: calc(100% + 4px);
                height: calc(100% + 4px);
                animation: glowing 20s linear infinite;
                opacity: 1;
                transition: opacity .3s ease-in-out;
                border-radius: 10px;
            }
            /* &:active {
                color: #000
            } */
            /* &:active:after {
                background: transparent;
            }
            &:hover:before {
                opacity: 1;
            }
            &:after {
                z-index: -1;
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                background: #ffffff;
                background: linear-gradient(to bottom, #FFFFFF 0%, #FFFFFF 0%, #C59237 100%);
                left: 0;
                top: 0;
                
                /* border-radius: 10px;
            } */
            @keyframes glowing {
                0% { background-position: 0 0; }
                50% { background-position: 400% 0; }
                100% { background-position: 0 0; }
            }
    }
}
@media screen and (min-width: 280px) and (max-width: 1079px) {
        margin-top: 1rem;
        margin-left: 1rem;
        margin-right: 1rem;
        height: 95vh;
        width: auto;
        .modal-container{
            .copy{
                h1{
            
            font-size: 1.5rem;
            padding: .5rem;
        }
        h2{
            padding: 1rem;
            font-size: 1.1rem;
        }
        h3{
            
            font-size: 2rem;
            padding: .5rem;
            
        }
        h4{
            font-size: 1.2rem;
            padding: .5rem;
        }
        h5{
            margin-top: 2rem;
            
            letter-spacing: 2px;
        }
                .book-mockup{
                    img{
                        height: 100px;
                    }
                }
            }
        }
    }
`;
