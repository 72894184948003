import React, { useContext } from 'react'
import styled from 'styled-components'
import languageDll from '../../../locales/books/dll/language-dll';
import { myContext } from '../../../context/ContextProvider';
import img from "../../../img/img"
import GoldButtonV2 from '../../common/GoldButtonV2';

export default function About(obj) {

    const { Language } = useContext(myContext);

    return (
        <>
            <BooksSection className='books'>
                <div className='book-section'>
                    <div className='book-title'>
                    
                        <h1 className='font-face-BodoniflfItalic'>{obj.prop.h1}</h1>
                        <h2 className='font-face-prata'>{obj.prop.h2}</h2>
                        <div className='underline'></div>
                        <div className='book-image-container'>
                            <img src={img[`book${Language}`]} alt="" />
                        </div>
                        <div className='copy'>
                            <p className='font-face-GlacialIndifference'>{obj.prop.copy}</p>
                        </div>
                        <div className='bonus-section'>
                            <div className='bonus'>
                                <h1 className='font-face-BodoniflfBold'>
                                    {languageDll[Language].about.bonus.h1}
                                </h1>
                                <div className='books'>
                                    <img className='phone' src={img[`phoneen`]} alt="Book" />
                                    <img
                                        className='book-cover'
                                        src={img[`manualcover${Language}`]} alt="Book"
                                    />
                                </div>
                                <div className='bonus-copy'>
                                    <h1 className='font-face-BodoniflfBold'>
                                        {languageDll[Language].about.bonus.h2}
                                    </h1>
                                    <h2 className='font-face-Kollektif-BoldItalic'>
                                        {languageDll[Language].about.bonus.h3}
                                    </h2>
                                    <h3 className='font-face-prata'>
                                        {languageDll[Language].about.bonus.copy}
                                    </h3>
                                </div>
                            </div>
                            <div className='formato'>
                                <p className='font-face-Kollektif-BoldItalic'>{obj.prop.formatos.p}</p>
                                <div className='formatos'>
                                    {
                                        obj.prop.formatos.arr.map((element, index) => (
                                            <div key={index}>
                                                <GoldButtonV2
                                                    text={element.formato}
                                                    link={element.link}
                                                />
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </BooksSection>
        </>
    )
}

const BooksSection = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fafafa;
    height: auto;
    max-width: 900px;
    margin-top: 6rem;
    margin-left: 1rem;
    margin-right: 1rem;
    box-shadow: inset -1px 3px 8px 5px #edeeef, 0px 12px 29px -10px #AA8658, 0px 2px 50px 15px rgba(0,0,0,0);
    /* border: #ff0000 solid 2px; */
    border-radius: 10px;
    text-align: center;
    .book-section{
        /* border: #9dff00 solid 2px; */
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .book-title{
            /* border: #0048ff solid 2px; */
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 3rem;
            h1{
                font-size: 2.4rem;
                padding: 1rem;
                letter-spacing: 2px;
                color: #AA8658;
            }
            h2{
                color: #AA8658;
                font-size: 1rem;
                letter-spacing: 2px;
                text-transform: uppercase;
                padding: 1rem;
                letter-spacing: 6px;
            }
            .underline{
                
            }
            .book-image-container{
                width: 50%;
                img{
                    width: 80%;
                }
            }
            .copy{
                /* border: #ff0000 solid 2px; */
                p{
                    font-size: 1.3rem;
                    padding-left: 6rem;
                    padding-right: 6rem;
                    padding-bottom: 6rem;
                    
                    letter-spacing: 2px;
                    line-height: 2.2rem;
                    text-align: justify;
                    color: #AA8658;
                }
            }
        }
        .bonus-section{
            /* border: lime 2px solid; //border */
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 90%;
            .bonus{
                /* border: #0073ff 2px solid; // border */
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                /* margin-top: 10rem; */
                background-color: #fafafa;
                /* height: 800px; */
                /* width: 668px; */
                width: 100%;
                /* box-shadow: inset -1px 3px 8px 5px #edeeef, 0px 12px 29px -10px #AA8658, 0px 2px 50px 15px rgba(0,0,0,0); */
                border-radius: 10px;
                h1{
                    font-size: 2rem;
                    padding: 4%;
                    text-transform: capitalize;
                    color: #AA8658;

                }
                .books {
                    background-color: #aa875826;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    /* height: 50%; */
                    width: 100%;
                    .book-cover{
                        /* border: #0073ff 2px solid; // border */
                        width: 30%;
                    }
                    .phone {
                        width: 10%;
                    }
                }
                .bonus-copy {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                    h1{
                        font-size: 2.3rem;
                    }
                    h2{
                        font-size: 1.3rem;
                        text-transform: uppercase;
                        letter-spacing: 4px;
                        text-align: center;
                        /* color: #4f4e4d; */
                    }
                    h3{
                        color: #4f4e4d;
                        padding: 2rem;
                        /* text-align: center; */
                        /* letter-spacing: 2px; */
                        /* line-height: 1.4rem; */
                        font-size: 1.2rem;
                        letter-spacing: 2px;
                        line-height: 2rem;
                        text-align: justify;
                        color: #AA8658;

                    }
                }
            }
            .formato{
                /* border: black 1px solid; */
                /* margin-top: 4rem; */
                width: 100%;
                margin-bottom: 2rem;
                p{
                    font-size: 1rem;
                    /* font-weight: bold; */
                    text-transform: uppercase;
                    letter-spacing: 4px;
                    text-align: center;
                    padding-top: 4rem;
                    padding-bottom: 1rem;
                }
                .formatos{
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
        /* .formato{
            p{

            }
            .formatos{
                display: flex;
                align-items: center;
            }
        } */
    }
    
    @media screen and (min-width: 280px) and (max-width: 1079px) {
        margin-left: 1rem;
        margin-right: 1rem;
        height: auto;
        width: 95%;
        /* border: green solid 2px; */
        .book-section{
            width: 100%;
            .book-title{
                h1{
                    /* padding: 5rem; */
                }
                .book-image-container{
                    /* border: yellow solid 2px; */
                    width: 100%;
                    
                }
                .copy{
                    p{
                        /* border: #40ff00 solid 2px; */
                        font-size: 1.3rem;
                        padding-left: 1rem;
                        padding-right: 1rem;
                        padding-bottom: 1rem;
                        letter-spacing: 2px;
                        line-height: 2rem;
                        text-align: justify;
                    }
                }
            }
            .bonus-section{
                /* border: #ff0000 solid 2px; */
                .bonus{
                    /* border: #33ff00 solid 2px; */
                    /* height: 400px;
                    width: 368px; */
                    .books{
                        /* border: #ff0000 solid 2px; */
                        .book-cover{
                            width: 50%;
                        }
                        .phone{
                            width: 20%;
                        }
                    }
                }
            }
        }
    }
`;
