import React, { useContext } from 'react'
import styled from 'styled-components';
import Footer from '../common/Footer';
import { myContext } from '../../context/ContextProvider';
import Banner from './Banner';
import CarrouselBoot from './CarrouselBoot';
import OtherItems from '../common/OtherItems';
import Compliance from '../common/Compliance';
import Navbar from '../common/NavBar';
import languageHome from "../../locales/home/language-home"
// import Project from '../common/Project';

export default function Main() {

    const { Language } = useContext(myContext);

    return (
        <>
            <MainHome>
                <Navbar />
                <Banner />
                {/* <CarouselHome carousel={pt.carousel} />, */}
                <CarrouselBoot carousel={languageHome[Language].carousel} />
                {/* <OtherItems obj={languageHome[Language].shopcart} /> */}
                <Footer footer={languageHome[Language].footer} />
                <Compliance/>
                {/* <Project/> */}
            </MainHome>
        </>
    )
}

const MainHome = styled.div`
background-color: #EBE7E3;
    h1{
        margin-top: 5rem;
    }
`;