import React, { useContext } from 'react'
import styled from 'styled-components'
import languageHome from '../../locales/home/language-home';
import { myContext } from '../../context/ContextProvider';
import Navbar from './NavBar';

export default function PrivacyTerms() {

    const { Language } = useContext(myContext)

    return (
        <>
            <Navbar navbar={languageHome[Language].navbar} />

            <PrivacyComponent>
                <div className='privacy-container'>
                    <iframe
                        type='application/pdf'
                        src={languageHome[Language].terms.link}
                        title="Privacy Terms"
                    />
                </div>
            </PrivacyComponent>
        </>
    )
}

const PrivacyComponent = styled.div`
    .privacy-container {
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        padding: 2%; /* Adiciona um pequeno padding para dar um respiro ao documento */

        iframe {
            width: 100%;
            height: 100%;
            border: none; /* Remove a borda do iframe */
        }
    }
`;
