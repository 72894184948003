import React, { useContext, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import languageHome from '../../locales/home/language-home';
import { myContext } from '../../context/ContextProvider';
import Navbar from '../common/NavBar';
import Footer from '../common/Footer';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

const Aditionalinfo = () => {
    const { Language } = useContext(myContext);

    const [showAlert, setShowAlert] = useState(false); // Estado para controlar a visibilidade do Alert
    const [alertVariant, setAlertVariant] = useState('success'); // Estado para controlar a cor do Alert


    const [formData, setFormData] = useState({
        name: '',
        email: '',
        date: '',
        language: "",
    });
    const [status, setStatus] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    const handleLanguageChange = (e) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            language: e.target.value
        }));
    };

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     setStatus('Enviando...');
    //     try {
    //         // const response = await axios.post('https://atcomercial-backend.vercel.app/api/new_map_contact', formData);
    //         const response = await axios.post('http://localhost:5002/api/new_map_contact', formData);
    //         setStatus(response.data.message);
    //         setFormData({ name: '', email: '', date: '' }); // Limpa o formulário
    //         setShowAlert(true); // Mostra o Alert
    //         if (response.data.success) {
    //             setAlertVariant('success'); // Define a cor do Alert para verde (sucesso)
    //         } else {
    //             setAlertVariant('danger'); // Define a cor do Alert para vermelho (erro)
    //         }
    //     } catch (error) {
    //         setStatus('Erro ao enviar a mensagem. Tente novamente mais tarde.');
    //     }
    // };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Verificar se todos os campos estão preenchidos
        if (!formData.name || !formData.email || !formData.date || !formData.language) {
            setStatus('');
            setShowAlert(true);
            setAlertVariant('danger');
            return; // Encerra a função se algum campo estiver vazio
        }

        setStatus('Enviando...');
        try {
            const response = await axios.post('https://atcomercial-backend.vercel.app/api/new_map_contact', formData);
            // const response = await axios.post('http://localhost:5002/api/new_map_contact', formData);
            setStatus(response.data.message);
            setShowAlert(true);
            setFormData({ name: '', email: '', date: '', language: '' }); // Limpa o formulário após o envio
            if (response.data.success) {
                setAlertVariant('success'); // Cor do Alert para verde (sucesso)
            } else {
                setAlertVariant('danger'); // Cor do Alert para vermelho (erro)
            }
        } catch (error) {
            setStatus('Erro ao enviar a mensagem. Tente novamente mais tarde.');
            setShowAlert(true);
            setAlertVariant('danger');
        }
    };


    return (
        <>
            <Navbar navbar={languageHome[Language].navbar} />
            <ContactContainer>
                <ContactHeader>{languageHome[Language].contact.aftersales.title}</ContactHeader>
                <ContactSubHeader>
                    {languageHome[Language].contact.aftersales.text2}
                </ContactSubHeader>
                <ContactSubHeader className='special-info'>
                    {languageHome[Language].contact.aftersales.text9}
                </ContactSubHeader>
                <ContactSubHeader className='font-face-poppins-bold'>
                    {languageHome[Language].contact.aftersales.text3}
                </ContactSubHeader>
                <ContactSubHeader>
                    {languageHome[Language].contact.aftersales.text4}
                </ContactSubHeader>
                <ContactSubHeader>
                    {languageHome[Language].contact.aftersales.text5}
                </ContactSubHeader>
                <ContactSubHeader>
                    {languageHome[Language].contact.aftersales.text6}
                </ContactSubHeader>
                <ContactSubHeader>
                    {languageHome[Language].contact.aftersales.text7}
                </ContactSubHeader>
                <ContactSubHeader>
                    {languageHome[Language].contact.aftersales.text8}
                </ContactSubHeader>
                <StyledForm onSubmit={handleSubmit}>
                    <div className='form-area'>
                        <label>{languageHome[Language].contact.aftersales.label2}</label>
                        <StyledInput type="text" name="name" value={formData.name} onChange={handleChange} required />
                    </div>
                    <div className='form-area'>
                        <label>{languageHome[Language].contact.aftersales.label3}</label>
                        <StyledInput type="date" name="date" value={formData.date} onChange={handleChange} />
                    </div>
                    <div className='form-area'>
                        <label>{languageHome[Language].contact.aftersales.label5}</label>
                        <StyledSelect name="language" value={formData.language} onChange={handleLanguageChange}>
                            <option value={languageHome[Language].contact.aftersales.label6}>{languageHome[Language].contact.aftersales.label6}</option>
                            <option value="Português">Português</option>
                            <option value="English">English</option>
                            <option value="Español">Español</option>
                            <option value="Deutsch">Deutsch</option>
                        </StyledSelect>
                    </div>
                    <div className='form-area'>
                        <label>{languageHome[Language].contact.label}</label>
                        <StyledInput type="email" name="email" value={formData.email} onChange={handleChange} required />
                    </div>
                    {/* <div>
                        <label>Message:</label>
                        <StyledTextArea name="message" value={formData.message} onChange={handleChange} required />
                    </div> */}
                    <StyledButton type="submit">Send</StyledButton>
                </StyledForm>
                {showAlert && (
                    <StyledAlert variant={alertVariant} onClose={() => setShowAlert(false)} dismissible>
                        <Alert.Heading>{status}</Alert.Heading>
                        {/* <StatusMessage >{status}</StatusMessage> */}
                    </StyledAlert>
                )}
            </ContactContainer>
            <Footer footer={languageHome[Language].footer} />
        </>
    );
}

export default Aditionalinfo;

const ContactContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 2rem;
    .special-info{
        color: #b95042;
        text-align: center;
        text-transform: uppercase;
        font-size: 1.2rem;
        letter-spacing: 3px;
    }
`;

const ContactHeader = styled.h2`
    color: #333;
    margin-bottom: 0.5rem;
`;

const ContactSubHeader = styled.p`
  color: #666;
  margin-bottom: 2rem;
  text-align: justify;
  width: 50%;
  @media screen and (max-width: 920px) {
      width: 100%;
    }
    `;

const StatusMessage = styled.p`
  color: #007bff;
  margin-top: 1rem;
`;

const StyledForm = styled.form`
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        background-color: #f4f4f4;
        border-radius: 10px;
        max-width: 400px;
        margin: 20px auto;
        .form-area{
            width: 100%;
        }
    `;

const StyledInput = styled.input`
        padding: 10px;
        margin: 10px 0;
        width: 100%;
        border-radius: 5px;
        border: 1px solid #ccc;
    `;

// const StyledTextArea = styled.textarea`
//         padding: 10px;
//         margin: 10px 0;
//         width: 100%;
//         height: 100px;
//         border-radius: 5px;
//         border: 1px solid #ccc;
//     `;

const StyledButton = styled.button`
        padding: 10px 20px;
        background-color: #007BFF;
        color: #fff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;
    
        &:hover {
            background-color: #0056b3;
        }
`;

const StyledSelect = styled.select`
    padding: 10px;
    margin: 10px 0;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #ccc;
`;

const StyledAlert = styled(Alert)` // Estilização adicional para o Alert, se necessário
    margin-top: 20px;
    position: fixed;
    top: 30%;
    z-index: 999;
`;