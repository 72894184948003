// eslint-disable-next-line import/no-anonymous-default-export
export default {
    links: {
        amazon: {
            paperback: "https://www.amazon.com.br/dp/B0CCL8XFNT",
            hardcover: "https://www.amazon.com.br/dp/B0CCL8XFNT"
        },
        whop: {
        },
        bookpreview: {
            dll: "https://www.africau.edu/images/default/sample.pdf#toolbar=0"
        }
    },
    hero: {
        title: "",
        copy: "Buscando um amor genuíno? Deseja compreender os mistérios que envolvem os relacionamentos e anseia por desvendar os segredos da mágica matemática do amor? Este livro será o seu guia definitivo.",
        img: "../img/dll/bookpt.png",
    },
    carrousel: [
        {
            img: "bookpt",
            button: "compre aqui",
            h1: "",
            copy: "Buscando um amor genuíno? Deseja compreender os mistérios que envolvem os relacionamentos e anseia por desvendar os segredos da mágica matemática do amor? Este livro será o seu guia definitivo.",
            h2: "",
            link: "/collections/deciphering-the-language-of-love?category=esoteric",
            preview: "Clique para Amostra",
            previewLink: "https://firebasestorage.googleapis.com/v0/b/image-provider-b2b36.appspot.com/o/politica%2FPoli%CC%81tica%20de%20Privacidade.pdf?alt=media&token=7153f364-be29-4823-9e19-778356331fbd&_gl=1*55yrzm*_ga*MTI5MDk5OTI5Mi4xNjg4NTU2MzQw*_ga_CW55HF8NVT*MTY5NzcwOTkxOC41MC4xLjE2OTc3MDk5NjQuMTQuMC4w",
        },
        {
            img: "dll_discount_s_pt",
            button: "compre aqui",
            h1: "Oferta Imperdível para versão digital E-book",
            copy: "Trilogia Numerológica + Aplicativo de Cálculo e Mapa Personalizado! Desvende os números com nossa Trilogia Numerológica.",
            h2: "Adquira já e transforme sua vida!",
            link: "/collections/deciphering-the-language-of-love_discount?category=esoteric",
            preview: "",
            previewLink: "",
        },
        {
            img: "map_book_pt",
            button: "compre aqui",
            h1: "Mapa numerológico",
            copy: "Um Mapa Numerológico Personalizado que revelará os segredos do seu destino e guiará cada passo da sua jornada. Transforme sua vida com insights exclusivos. Reserve agora o seu Mapa Numerológico e dê o primeiro passo em direção a uma vida mais consciente e gratificante.",
            h2: "",
            link: "/collections/numerological_map?category=esoteric",
            preview: "",
            previewLink: "",
        },
    ],




    testimony: {
        title: "Testemunho",
        text1: {
            text: "Depois de ler este livro, percebi como os números podem realmente influenciar nossos relacionamentos. Ele me deu uma compreensão profunda e uma nova perspectiva sobre o amor. Altamente recomendado!",
            user: "J.C silva",
        },
        text2: {
            text: "Este livro de numerologia do amor é um tesouro! Leitura obrigatória para todos em busca de conexões verdadeiras.",
            user: "ana s.",
        },
        text3: {
            text: "Como alguém que sempre se interessou pelo lado mais espiritual do amor, este livro foi um achado incrível. Agora, tenho ferramentas valiosas para entender e aprimorar meus relacionamentos. Um livro verdadeiramente inspirador!",
            user: "carlos m.",
        },
    },
    about: {
        h1: "Decifrando a Linguagem do Amor",
        h2: "A Compreensão dos Relacionamentos Através dos Números",
        copy: "Adentre neste universo fascinante e decifre a linguagem única do amor através dos números. Porque, quando se trata de assuntos do coração, cada número conta uma história única e preciosa. Este é o seu convite para ouvir essas histórias, para desvendar os segredos do amor e encontrar um caminho mais profundo e significativo nas relações humanas. Afinal, o amor é a maior história já contada, e os números são as palavras que a tornam completa.",
        bonus: {
            h1: "BÔNUS",
            h2: "Grátis",
            h3: "Aplicativo e livro Manual prático",
            copy: "Adquirindo o livro Decifrando a Linguagem do Amor em formato digital e-Book, você recebe gratuitamente o livro Manual Prático - O Poder da Numerologia: Desvendando os Segredos do Sucesso + acesso ao Aplicativo da Calculadora de Numerologia.",
        },
        formatos: {
            p: "Formatos disponíveis:",
            arr: [
                {
                    formato: "E-book + Bônus",
                    link: "/collections/deciphering-the-language-of-love?category=esoteric"
                },
                {
                    formato: "Livro impresso",
                    link: "/collections"
                }
            ]
        }




    },
    otherBooks: [
        {
            title: "Recomendações",
            copytitle: "O Poder da Numerologia",
            subtitle: "Desvendando os Segredos do Sucesso",
            copy: "Desvende os mistérios dos números e eleve sua evolução para o próximo nível. Explore o poder dos números e transforme sua vida.",
            button: {
                text: "Peça já",
                link: "https://thepowerofnumerology.com"
            },
            img: "poncoverpt",
        },
        {
            title: "Recomendações",
            copytitle: "Mapa Numerológico",
            subtitle: "Mapa numerológico personalizado",
            copy: "Descubra seus números pessoais em uma análise exclusiva com o próprio autor da trilogia 'O Poder da Numerologia'. Uma experiência totalmente personalizada para você.",
            button: {
                text: "Peça já",
                link: "/collections/numerological_map?category=esoteric"
            },
            img: "map_pt_s",
        },
    ],
    modal: {
        h1: "Oferta Imperdível:",
        h2: "eBook - Trilogia Numerológica + Aplicativo de Cálculo + Mapa Personalizado!",
        h3: "60% Desconto",
        h4: "Desvende os números com nossa Trilogia Numerológica, ganhe acesso exclusivo ao Aplicativo de Cálculo Numerológico e receba um Mapa Personalizado para iluminar seu caminho como nunca antes. ",
        h5: "Adquira já e transforme sua vida!",
        button: "COMPRE AQUI",
        img: {
            1: "manualcoverpt",
            2: "bookpt",
            3: "poncoverpt",
        }
    },
    shopcart: {
        otherItems: {
            title: "ITENS RECOMENDADOS",
            arr: [
                {
                    title: "oferta imperdível",
                    item: "Trilogia numerológica + mapa numerológico + app",
                    format: "E-Book - Download",
                    img: "dll_discount_pt",
                    price: "R$ 414,99",
                    link: "",
                },
                {
                    title: "",
                    item: "Mapa numerológico PERSONALIZADO",
                    format: "E-Book - Download",
                    img: "map_pt_s",
                    price: "R$ 970,99",
                    link: "",
                },
                {
                    title: "",
                    item: "O Poder da numerologia - desvendando os segredos do sucesso",
                    format: "E-Book - Download",
                    img: "pon_pt_s",
                    price: "R$ 24,99",
                    link: "",
                },
                {
                    title: "",
                    item: "DECIFRANDO A LINGUAGEM DO AMOR + BÔNUS",
                    format: "E-Book - Download",
                    img: "dllpt",
                    price: "R$ 24,99",
                    link: "",
                },
            ],
        },
    },
}

