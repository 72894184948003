import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { myContext } from '../../context/ContextProvider';
import styled from 'styled-components'
import Navbar from '../common/NavBar';
import OtherItems from '../common/OtherItems';
import languageHome from '../../locales/home/language-home';
import ShopPagev2 from './ShopPagev2';
import Footer from '../common/Footer';

export default function ShopingCart() {
  const { Language } = useContext(myContext);
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const category = searchParams.get('category'); // category do livro
  const [book, setBook] = useState(null);

  // let itemsParams = languageHome[Language].shopcart;

  useEffect(() => {
    async function fetchBook() {
      try {
        const books = await import(`../../locales/books/books-${Language}.json`);
        const categoryBooks = books[category] || [];
        const foundBook = categoryBooks.find(book => book.id === id);
        if (foundBook) {
          setBook(foundBook);
        } else {
          navigate("/404");
        }
      } catch (error) {
        console.error("Failed to load books data:", error);
        navigate("/404");
      }
    }

    if (category) {
      fetchBook();
    }
  }, [id, category, Language, navigate]);

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if (!book) {
    // navigate("/404");
    return null;
  }

  // const book = booksExporter[Language][id]; // CAMINHO DO ARQUIVO JSON DE LIVROS
  return (
    <>
      <ShopingCartSection>
        <Navbar navbar={languageHome[Language].navbar} />
        {/* <ShopPage obj={parameter} /> */}
        <ShopPagev2 obj={book} />
        {/* <OtherItems obj={languageHome[Language].shopcart} /> */}
        {/* <SslSecureBadge/> */}
        <Footer footer={languageHome[Language].footer} />
        
      </ShopingCartSection>
    </>
  )
}

const ShopingCartSection = styled.div`
  height: auto;
  width: 100%;
`;
