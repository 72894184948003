// eslint-disable-next-line import/no-anonymous-default-export
export default {
    navbar: {
        logo: "",
        linksArr: [
            {
                text: "Home",
                link: "/",
                icon: "BiHomeAlt",
            },
            {
                text: "Coleções",
                link: "/collections",
                icon: "MdOutlineCollectionsBookmark",
            },
            {
                text: "Contato",
                link: "/contact",
                icon: "MdOutlineAlternateEmail",
            },
            {
                text: "FAQ",
                link: "/faq",
                icon: "FaQuestionCircle",
            },
        ]
    },
    home: {
        welcome: {
            text1: "Bem vindo a",
            text2: "Em cada página, um novo horizonte. Embarque em jornadas inesquecíveis"
        },
        button: {
            text: "coleções",
            link: "/collections",
        }
    },
    newsletter:{
        title: "Assine nossa Newsletter",
        email: "Seu e-mail aqui",
        button: "Submeter",
        manage: "Para gerenciar sua inscrição clique",
        here: "aqui",
        unsub: "Cancelar subscrição da nossa Newsletter",
        emailunsub:"Digite seu e-mail para cancelar a inscrição",
        buttonunsub: "Cancelar subscrição"
    },
    contact: {
        title: "Entre em contato conosco!",
        text: "Nós, da A&T Tales, estamos sempre prontos para ouvir você. Seja uma dúvida sobre nossos livros, uma questão sobre o seu pedido ou até mesmo um feedback sobre sua leitura, sua voz é valiosa para nós.",
        text2: "Preencha o formulário abaixo ou envie-nos um e-mail diretamente para info@aettales.com. Prometemos responder o mais rápido possível com toda a atenção que você merece.",
        text3: "Para questões relacionadas a suporte ou pós-venda, por favor, entre em contato através de contact@aettales.com.",
        text4: "Agradecemos por escolher a A&T Tales, onde cada história é uma nova jornada!",
        label: "Email: ",
        label2: "Nome: ",
        label3: "Assunto: ",
        label4: "Mensagem: ",
        label5: "Enviar",
        email: "info@aettales.com",
        error: 'Erro ao enviar a mensagem. Tente novamente mais tarde.',
        aftersales: {
            label2: "Nome Completo de Nascimento: ",
            label3: "Data de Nascimento: ",
            label4: "Língua do Mapa Numerológico",
            label5: "Escolha um idioma para o mapa",
            title: "Agradecemos pela sua compra!",
            text: "Caro leitor(a), ",
            text2: "É com grande alegria que confirmamos o recebimento do seu pedido! Estamos empolgados por você ter escolhido a A&T Tales para ser sua companheira nas próximas páginas de aventura e conhecimento.",
            text3: "O próximo passo para receber o seu mapa numerológico é nos enviar as suas informações. Por favor, preencha o formulário abaixo com o seu nome de nascimento, sua data de nascimento e seu e-mail cadastrado na compra. Após recebermos os seus dados, iremos começar a trabalhar na criação do seu mapa personalizado. O tempo estimado para a conclusão é de 3 dias úteis. Enviaremos um e-mail com link para download do arquivo.",
            text4: "Alguma dúvida?",
            text5: "Estamos aqui para ajudar. Se você tiver qualquer pergunta ou precisar de assistência, sinta-se à vontade para entrar em contato conosco no link de contato da página.",
            text6: "Aproveite a leitura e que ela seja apenas o começo de uma longa história entre você e a A&T Tales.",
            text7: "Com apreço,",
            text8: "A Equipe da A&T Tales",
            text9: "Atenção",
            alert: "Por favor, preencha todos os campos."
        }
    },
    faq: {
        title: "FAQ - Perguntas Frequentes",
        arr: [
            {
                question: "Como posso comprar um livro?",
                answer: "Você pode comprar nossos livros diretamente no nosso site. Escolha o livro que deseja, adicione ao carrinho e siga para o checkout para finalizar a compra."
            },
            {
                question: "Quais métodos de pagamento são aceitos?",
                answer: "Aceitamos diversos métodos de pagamento, incluindo cartão de crédito, PayPal e Pix. Selecione o método que preferir no momento do pagamento."
            },
            {
                question: "Os livros estão disponíveis em quais formatos?",
                answer: "Nossos livros estão disponíveis em formato físico(capa dura e capa comum) e digital(eBook). Na página de cada livro, você encontrará as opções de formato disponíveis."
            },
            {
                question: "Como posso acessar o eBook após a compra?",
                answer: "Após a compra, você poderá efetuar o download instantaneamente pelo link fornecido na página e ficará disponível na sua conta Whop"
            },
            {
                question: "É seguro comprar no site?",
                answer: "Nosso provedor de pagamento Whop e Amazon, utiliza tecnologia de criptografia SSL para proteger seus dados pessoais e financeiros. Sua segurança é nossa prioridade."
            },
            {
                question: "Posso devolver um livro se não estiver satisfeito?",
                answer: "Para livros físicos entre em contato diretamente com a Amazon BR onde efetuou a compra. Para eBooks, entre em contato pelo formulário do link Contato ou pelo e-mail info@aettales.com, e solicite um reembolso em até 14 dias após a compra."
            },
            {
                question: "Como posso entrar em contato se tiver outras perguntas?",
                answer: "Se tiver mais perguntas, você pode entrar em contato conosco através da nossa página de contato ou pelo e-mail info@aettales.com. Nossa equipe está pronta para ajudar!"
            },
        ]
    },
    carousel: [
        // {
        //     image: "banner_trology_discount_1500_pt",
        //     imagemob: "dll_discount_pt",
        //     text: "Desvende os mistérios dos números e eleve sua evolução para o próximo nível. Explore o poder dos números e transforme sua vida.",
        //     title: "O Poder da Numerologia",
        //     link: "/collections/numerology-trilogy?category=esoteric"
        // },
        // {
        //     image: "banner_trology_discount2_1500_pt",
        //     imagemob: "cover_trology_discount_pt",
        //     text: "Desvende os mistérios dos números e eleve sua evolução para o próximo nível. Explore o poder dos números e transforme sua vida.",
        //     title: "O Poder da Numerologia",
        //     link: "/collections/deciphering-the-language-of-love_discount?category=esoteric"
        // },
        {
            image: "banner_ecos_pt",
            imagemob: "banner_ecos_pt",
            text: "",
            title: "Echoes of 3077",
            link: "/collections/echoes-of-3077?category=science-fiction"
        },
        {
            image: "banner_dll_1500_pt",
            imagemob: "banner_dll_mobile_pt",
            text: "Desvende os mistérios dos números e eleve sua evolução para o próximo nível. Explore o poder dos números e transforme sua vida.",
            title: "Decifrando a Linguagem do Amor",
            link: "/deciphering-the-language-of-love"
        },
        {
            image: "banner_pon_1500_pt",
            imagemob: "banner_pon_mobile_pt",
            text: "Desvende os mistérios dos números e eleve sua evolução para o próximo nível. Explore o poder dos números e transforme sua vida.",
            title: "O Poder da Numerologia",
            link: "/collections/the-power-of-numerology?category=esoteric"
        },
    ],
    shopcart: {
        button: "Comprar",
        otherItems: {
            title: "LIVROS RECOMENDADOS",
            arr: [
                {
                    title: "",
                    item: "Ecos de 3077 - O Despertar - Parte I",
                    format: "E-Book - Download",
                    img: "cover_echoes_en",
                    price: "$ 14,99",
                    link: "/collections/echoes-of-3077?category=science-fiction",
                },
                // {
                //     title: "",
                //     item: "Trilogia numerológica + mapa numerológico + app",
                //     format: "E-Book - Download",
                //     img: "dll_discount_pt",
                //     price: "R$ 414,99",
                //     link: "/collections/deciphering-the-language-of-love_discount?category=esoteric",
                // },
                // {
                //     title: "",
                //     item: "Trilogia numerológica + app",
                //     format: "E-Book - Download",
                //     img: "cover_trology_discount_pt",
                //     price: "R$ 45,47",
                //     link: "/collections/deciphering-the-language-of-love_discount?category=esoteric",
                // },
                // {
                //     title: "",
                //     item: "Mapa numerológico PERSONALIZADO",
                //     format: "E-Book - Download",
                //     img: "map_pt_s",
                //     price: "R$ 970,99",
                //     link: "/collections/numerological_map?category=esoteric",
                // },
                {
                    title: "",
                    item: "O Poder da numerologia - desvendando os segredos do sucesso",
                    format: "E-Book - Download",
                    img: "pon_pt_s",
                    price: "R$ 24,99",
                    link: "/collections/the-power-of-numerology?category=esoteric",
                },
                {
                    title: "",
                    item: "DECIFRANDO A LINGUAGEM DO AMOR + BÔNUS",
                    format: "E-Book - Download",
                    img: "dllpt",
                    price: "R$ 24,99",
                    link: "/collections/deciphering-the-language-of-love?category=esoteric",
                }
            ],
        },
    },
    collection: {
        section_title: "Coleções",
        add: "Adicionar ao carrinho",
        available: "Compra segura | Criptografia SSL | Provedor Whop",
        recomend: "Recomendar",
        showmore: "mostre mais",
        example: "exemplo de leitura",
        pages: "Número de páginas",
        language: "Linguagem",
        editor: "Editor",
        isbn: "ISBN-13",
        more: "Mais de ",
        bradcrumb: "eBooks",
        description: "Descrição",
        details: "Detalhes",
        preview_image: "CLIQUE PARA PREVIEW",
        sicialshare: "Compartilhe",
        collection_title: {
            esoteric: "eBooks Esotéricos",
            scifi: "Ficção científica"
        },
        biblio: {
            pages: "Páginas",
            language: "Linguagem",
            version: "versão",
            publisher: "Editora",
            isbn: "ISBN",
        },
        info: {
            info: "Informações sobre e-books",
            infoText: "Nossos e-books são cuidadosamente criados para oferecer a você uma experiência de leitura envolvente e acessível. Para garantir que você aproveite ao máximo sua biblioteca digital, aqui estão algumas informações importantes:",
            format: "Formato de arquivo: EPUB",
            formatText: "Todos os nossos e-books estão disponíveis no formato de arquivo EPUB. Isso significa que você pode lê-los em uma ampla variedade de dispositivos, incluindo seu computador, tablet, smartphone ou e-reader. A simplicidade do formato EPUB permite que você desfrute da sua leitura em qualquer lugar e a qualquer momento, sem a necessidade de software especializado.",
            detail: "Detalhes do formato e proteção",
            detailText: "Na visualização detalhada de cada título, você encontrará informações específicas sobre o formato do e-book e qualquer proteção contra cópia, quando aplicável. Nossa prioridade é garantir que você tenha todas as informações necessárias para desfrutar da sua leitura sem complicações.",
            transfer: "Transferência fácil para o seu dispositivo",
            transferText: "Transferir nossos e-books para o seu dispositivo é simples. E-books sem proteção contra cópia ou com marca d'água digital podem ser facilmente transferidos para o seu dispositivo, garantindo uma experiência de leitura fluida. Certifique-se de verificar a compatibilidade com o seu dispositivo para uma transição tranquila."
        }
    },
    footer: [
        {
            link: "/contact",
            text: "Contato",
        },
        {
            link: "/privacy",
            text: "Privacidade e Termos de Uso",
        },
        {
            text: "FAQ",
            link: "/faq"
        },
        {
            text: "Newsletter",
            link: "/newsletter"
        }
    ],
    terms: {
        text: "Este site utiliza o Google Analytics para coletar dados de análise, a fim de melhorar a experiência do usuário. Essas informações são coletadas de forma anônima e incluem dados como localização geográfica, dispositivo utilizado, páginas visitadas e tempo gasto no site. Ao continuar navegando neste site, você concorda com a coleta e processamento desses dados pelo Google Analytics. Para obter mais informações sobre como o Google Analytics coleta e processa dados, consulte a nossa Política de Privacidade.",
        title: "Privacidade e Termos de Uso",
        link: "https://firebasestorage.googleapis.com/v0/b/image-provider-b2b36.appspot.com/o/politica%2FPoli%CC%81tica%20de%20Privacidade.pdf?alt=media&token=7153f364-be29-4823-9e19-778356331fbd&_gl=1*55yrzm*_ga*MTI5MDk5OTI5Mi4xNjg4NTU2MzQw*_ga_CW55HF8NVT*MTY5NzcwOTkxOC41MC4xLjE2OTc3MDk5NjQuMTQuMC4w",
    },
    compliance: {
        termsOfServiceTitle: "Terms of Service",
        termsOfServiceText: "By using this app, you agree to our terms of service.",
        privacyPolicyTitle: "Privacy Policy",
        privacyPolicyText: "We are committed to protecting your privacy. Read our privacy policy for more information."
    },
} 