import React from 'react'
import styled from 'styled-components';
import { AiOutlineShoppingCart } from "react-icons/ai"
import img from '../../img/img';
import { Link } from 'react-router-dom';

export default function OtherItems({ obj }) {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Remove se quiser uma transição instantânea
        });
    }
    return (
        <>
            <OtherItemsComponent>
                <div className='card-container'>
                    <h1 className='font-face-GlacialIndifference'>{obj.otherItems.title}</h1>
                    <div className='wraper' >
                        {
                            obj.otherItems.arr.map((item, index) => (
                                <div className='card' key={index}>
                                    <div className='image-container'>
                                        <Link id='link-to-book' to={item.link} onClick={scrollToTop}>
                                            <img src={img[item.img]} alt="" />
                                        </Link>
                                    </div>
                                    <div className='card-info'>
                                        <p className='card-title'>{item.title}</p>
                                        <p className='card-item font-face-ChauPhilomeneOne'>{item.item}</p>
                                        <p className='card-format font-face-GlacialIndifference'>{item.format}</p>
                                        <Link id='link-to-book' className='card-link' to={item.link} onClick={scrollToTop}>
                                            <div className='card-button'>
                                                <p className='button-price'>{item.price}</p>
                                                <AiOutlineShoppingCart className='button-icon' />
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </OtherItemsComponent>
        </>
    )
}

const OtherItemsComponent = styled.div`
display: flex;
align-items: center;
justify-content: center;
/* margin-top: 8rem; */
    .card-container{
        max-width: 1320px;
        height: auto;
        /* border: #e9e9e9 solid 2px; */
        background-color: #d1d1d1;
        margin-top: 3rem;
        padding: 2rem;
        border-radius: 10px;
        h1{
            font-size: 1.2rem;
            text-transform: uppercase;
            letter-spacing: 2px;
        }
        .wraper{
            /* border: #ffff0019 solid 2px; */
            padding-top: 3rem;
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: row;
            /* flex-wrap: wrap; */
            justify-content: space-around;
            gap: 1rem;
            .card{
                border: #808080 solid 1px;
                border-radius: 6px;
                background-color: #ffffff;
                width: 180px;
                height: 500px;
                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;
                .image-container{
                    /* border: #ff0000 solid 2px; */
                    position: absolute;
                    height: 200px;
                    width: 144px;
                    top: -30px;
                    -webkit-box-shadow: 3px 36px 50px -7px rgba(0,0,0,0.56); 
                    box-shadow: 3px 36px 50px -7px rgba(0,0,0,0.56);
                    :hover{
                        transform: scale(1.1);
                        transition: 1s ease-in-out;
                    }
                    img{
                        transition: 1s ease-in-out;
                        width: 100%;
                        object-fit: cover;
                    }
                }
                .card-info{
                    text-align: center;
                    width: 100%;
                    height: 50%;
                    margin-top: 200px;
                    .card-link{
                        text-decoration: none;
                        color: #252525;
                    }
                    .card-title{
                        color: #ff001e;
                        font-size: 1.2rem;
                        text-transform: uppercase;
                        letter-spacing: 2px;
                    }
                    .card-item{
                        /* border: #48ff00 2px solid; */
                        text-transform: uppercase;
                        font-size: .8rem;
                        padding: 0.5rem;
                        letter-spacing: 2px;
                        /* text-align: justify; */
                    }
                    .card-format{
                        text-transform: uppercase;
                        font-size: .8rem;
                        padding: .5rem;
                        position: absolute;
                        bottom: 4rem;
                        text-align: center;
                    }
                    .card-button{
                        cursor: pointer;
                        position: absolute;
                        bottom: 0;
                        border: #252525 1px solid;
                        border-radius: 5px;
                        width: 80%;
                        padding: .5rem;
                        margin: 1rem;
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        
                        background: #FFFFFF;
                        background: -moz-linear-gradient(top, #FFFFFF 0%, #FFFFFF 0%, #C59237 100%);
                        background: -webkit-linear-gradient(top, #FFFFFF 0%, #FFFFFF 0%, #C59237 100%);
                        background: linear-gradient(to bottom, #FFFFFF 0%, #FFFFFF 0%, #C59237 100%);
                        transition: 0.3s ease-in-out;
                        box-shadow: inset 0px 0px 7px 3px #fff, 0px 12px 29px -10px #AA8658, 0px 2px 50px 15px rgba(0,0,0,0);
                        .button-price{
                            /* border: #252525 1px solid; */
                            letter-spacing: 2px;
                            margin: 0;
                        }
                        .button-icon{
                            font-size: 1.5rem;
                            color: #2d2d2d;
                        }
                    }
                }
            }
        }
    }
    @media screen and (min-width: 280px) and (max-width: 1080px){
        .card-container{
            .wraper{
                flex-wrap: wrap;
                .card{
                    flex: 1 1 calc(50% - 2rem);  // Isso garante que cada card tente ocupar cerca de 50% da largura do wraper, mas subtrai 2rem para considerar o gap entre eles.
                    margin-bottom: 3rem;
                    &:last-child {
                        max-width: 450px;
                    }
                }
            }
        }
    }
`;