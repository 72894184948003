import React from 'react';
import BookCard from './BookCard';
import styled from 'styled-components';

const BooksContainer = ({ books, sectionTitle }) => {
    // Este método divide o array de livros em subarrays com o tamanho desejado.
    const chunkedBooks = (arr, size) =>
        arr.reduce((acc, e, i) => (
            i % size ? acc[acc.length - 1].push(e) : acc.push([e]), acc
        ), []);

    // Divida os livros em grupos de 3 para o exemplo (altere conforme a necessidade)
    const groupedBooks = chunkedBooks(books, 6);

    return (
        <BooksContainerStyle>
            <SectionName>
                <p className='text-section-title font-face-quattocento'>
                    {sectionTitle}
                </p>
            </SectionName>
            {groupedBooks.map((group, idx) => (
                <div className='books-container-wrapper' key={idx} >
                    {group.map(book => (
                        <BookCard key={book.id} book={book} />
                    ))}
                </div>
            ))}
        </ BooksContainerStyle>
    );
};

export default BooksContainer;

const BooksContainerStyle = styled.div`
background-color: #d1d1d1;
margin-top: 2rem;
/* max-width: 1320px; */
    .books-container-wrapper{
        /* border: #00ff1a 1px solid; */
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
`;

const SectionName = styled.div`
    text-align: left;
    padding-left:2rem ;
    font-size: 24px;
    margin-bottom: 16px;
    letter-spacing: 2px;
    /* text-transform: uppercase; */
    /* Add any additional styling you want for the section name */
`;
