import React, { useState, useContext } from 'react';
import axios from 'axios';
import { Button, Form, Container, Spinner } from 'react-bootstrap'; // Import Spinner aqui
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from '../common/NavBar';
import languageHome from "../../locales/home/language-home"
import { myContext } from '../../context/ContextProvider';
import { Link } from 'react-router-dom';

export default function NewsletterSignup() {
    const { Language } = useContext(myContext);
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false); // Estado para controlar a visibilidade da barra de progresso

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true); // Ativa a barra de progresso
        try {
            const response = await axios.post('https://atcomercial-backend.vercel.app/api/newsletter/subscribe', { email });
            alert(response.data);
            setEmail(''); // Limpa o campo de e-mail após o envio bem-sucedido
        } catch (error) {
            alert(error.response.data);
        } finally {
            setIsLoading(false); // Desativa a barra de progresso
        }
    };

    return (
        <>
        <Navbar/>
        <Container className="my-4">
            <h2>{languageHome[Language].newsletter.title}</h2>
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                    <Form.Control type="email" placeholder={languageHome[Language].newsletter.email} value={email} onChange={(e) => setEmail(e.target.value)} />
                </Form.Group>
                <Button variant="primary" type="submit" disabled={isLoading}>
                {isLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : languageHome[Language].newsletter.button}
                </Button>
            </Form>
            <p className='mt-5'>
            {languageHome[Language].newsletter.manage} 
                <Link to="/newsletterunsubscribe"> {languageHome[Language].newsletter.here}</Link>
            </p>
        </Container>
        </>
    );
}
