import React, { useContext, useState } from 'react';
import { myContext } from '../../context/ContextProvider';
import { Link } from 'react-router-dom';
import languageHome from '../../locales/home/language-home';
import styled from 'styled-components';

const Compliance = () => {
    const { Language } = useContext(myContext);
    const [isOpen, setIsOpen] = useState(localStorage.getItem("modalClosed") === null);
    const [isChecked, setIsChecked] = useState(true);
    const [notChecked, setNotChecked] = useState(false);

    const handleClose = () => {
        if (isChecked === true) {
            localStorage.setItem("modalClosed", "true");
            setIsOpen(false);
        } else if (notChecked === true) {
            setIsOpen(false);
        }
    };

    const handleInput = (checkboxId) => {
        if (checkboxId === "accept") {
            setIsChecked(!isChecked);
            setNotChecked(false);
        } else if (checkboxId === "reject") {
            setNotChecked(!notChecked);
            setIsChecked(false);
        }
    }

    React.useEffect(() => {
        // Só executa a função quando o componente é montado
        if (localStorage.getItem("modalClosed")) {
            setIsOpen(false);
        }
    }, []);

    return (
        <>
            <ComplianceStyle>
                {isOpen && (
                    <div className="compliance-modal-overlay">
                        <div className="compliance-modal">
                            <h1 className='h1-title'>Cookies & Privacy</h1>
                            <div className='text-container'>
                                <p className='modal-text'>
                                    {languageHome[Language].terms.text}
                                </p>
                                <Link to="/privacy"
                                    >
                                    <p>{languageHome[Language].terms.title}</p>
                                </Link>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="accept"
                                        checked={isChecked}
                                        onChange={() => handleInput("accept")}
                                    />
                                    <label className="form-check-label" htmlFor="accept">
                                        <p className='check-text'>
                                            <strong>Accept</strong>
                                        </p>
                                    </label>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="reject"
                                        checked={notChecked}
                                        onChange={() => handleInput("reject")}
                                    />
                                    <label className="form-check-label" htmlFor="reject">
                                        <p className='check-text'>
                                            <strong>Reject</strong>
                                        </p>
                                    </label>
                                    <button onClick={handleClose}>OK</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </ComplianceStyle>
        </>
    );
};

export default Compliance;

const ComplianceStyle = styled.div`
/* border: red 1px solid; */
width: 100%;
display: flex;
align-items: center;
justify-content: center;
position: fixed;
bottom: 2rem;
left: 0;
z-index: 999;
.compliance-modal-overlay {
    /* border: #001aff 1px solid; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* width: 100%; */
    /* height: auto; */
    /* background-color: rgba(0, 0, 0, 0.506); */
    z-index: 3;
    .compliance-modal {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* margin: 10px; */
        background-color: #ffffff;
        /* padding: 10px; */
        border-radius: 8px;
        width: 90%;
        height: auto;
        text-align: center;

        .h1-title{
            font-size: 1.2rem;
            margin: 0;
            margin-top: 1rem;
        }
        .text-container{
            .modal-text{
                text-align: justify;
                padding-top: 1rem;
                line-height: 1.2rem;
                padding-left: 1rem;
                padding-right: 1rem;
            }
            .form-check {
                /* border: red 1px solid; */
                display: flex;
                justify-content: center;
                align-items: center;
                .form-check-label{
                    /* border: #0008ff 1px solid; */
                    /* height: 100%; */
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .check-text{
                        // width: 70%;
                        color: black;
                        line-height: 15px;
                        padding-right: 2rem;
                        padding-left: 2rem;
                        margin: 0;
                    }
                }
                button {
                    height: 30px;
                    width: auto;
                    padding: 5px 10px;
                    background-color: #333;
                    color: #fff;
                    border: none;
                    border-radius: 4px;
                    cursor: pointer;
                    margin-left: 10%;
                    margin-bottom: 20px;
                    /* padding: 10px; */
                }
            }
        }
    }
}

@media screen and (max-width: 780px) {
    .modal {
        .modal-text {
            // display: none;
        }
    }
}

`;