import bookpt from "./dll/book-pt.png"
import phoneen from "./dll/phone-em.png"
import manualcoverpt from "./dll/manual-cover-pt.png"
import manualcoveren from "./dll/manual-cover-en.png"
import backgroundsale from "./dll/background-sale.png"
import poncoverpt from "./dll/pon-cover-pt.png"
import triobookspt from "./dll/3books-pt.png"
import map from "./dll/map.png"

const locker = "https://firebasestorage.googleapis.com/v0/b/image-provider-b2b36.appspot.com/o/aethome%2Flocker.png?alt=media&token=4d81603a-af39-4031-9858-6276602bc388&_gl=1*xwibrx*_ga*MTI5MDk5OTI5Mi4xNjg4NTU2MzQw*_ga_CW55HF8NVT*MTY5OTQzMTYyMC43Ny4xLjE2OTk0MzE2MzguNDIuMC4w";
const ssl = "https://firebasestorage.googleapis.com/v0/b/image-provider-b2b36.appspot.com/o/aethome%2FSSL-Secure.png?alt=media&token=b1de4de0-db23-4ecf-b22d-e0836f025a10&_gl=1*17vnl60*_ga*MTI5MDk5OTI5Mi4xNjg4NTU2MzQw*_ga_CW55HF8NVT*MTY5OTQzNTYxNy43OC4xLjE2OTk0MzU2MzYuNDEuMC4w";
const stamp = "https://firebasestorage.googleapis.com/v0/b/image-provider-b2b36.appspot.com/o/aethome%2Fstamp_secure_shoping.png?alt=media&token=30c0ce65-8d9c-4ba9-91f2-d9499001b223&_gl=1*abjeo4*_ga*MTI5MDk5OTI5Mi4xNjg4NTU2MzQw*_ga_CW55HF8NVT*MTY5OTQzODAzMy43OS4xLjE2OTk0MzgwNTEuNDIuMC4w";
const dllpt = "https://firebasestorage.googleapis.com/v0/b/image-provider-b2b36.appspot.com/o/dll-pt2.png?alt=media&token=4332c582-e770-41b6-a13a-557b13f6cfa1&_gl=1*a5qvct*_ga*MTI5MDk5OTI5Mi4xNjg4NTU2MzQw*_ga_CW55HF8NVT*MTY5NjkyNTg2Mi40My4xLjE2OTY5MjcxMDAuMTguMC4w";
const pon_pt_s = "https://firebasestorage.googleapis.com/v0/b/image-provider-b2b36.appspot.com/o/pon_pt_s.png?alt=media&token=3a819a3d-2cd9-487e-818e-d2008f3353fa&_gl=1*uyixzw*_ga*MTI5MDk5OTI5Mi4xNjg4NTU2MzQw*_ga_CW55HF8NVT*MTY5NzE4OTcyNS40NC4xLjE2OTcxODk4MDQuNjAuMC4w";
const pon_manual_pt_s = "https://firebasestorage.googleapis.com/v0/b/image-provider-b2b36.appspot.com/o/pon_manual_pt_s.jpg?alt=media&token=ca350ca1-b5d5-415e-8a42-5c4123efd2a5&_gl=1*2g9rd2*_ga*MTI5MDk5OTI5Mi4xNjg4NTU2MzQw*_ga_CW55HF8NVT*MTY5NzE4OTcyNS40NC4xLjE2OTcxOTAyMDQuNDcuMC4w";
const map_pt_s = "https://firebasestorage.googleapis.com/v0/b/image-provider-b2b36.appspot.com/o/map_pt_s.png?alt=media&token=adf42df8-395d-4ddc-8827-62722a01b4f0&_gl=1*1vsi0kg*_ga*MTI5MDk5OTI5Mi4xNjg4NTU2MzQw*_ga_CW55HF8NVT*MTY5NzE5NDU4OC40NS4xLjE2OTcxOTQ2NDUuMy4wLjA.";
const map_pt_l = "https://firebasestorage.googleapis.com/v0/b/image-provider-b2b36.appspot.com/o/map_pt_l.png?alt=media&token=e91fbbf0-be8d-4b32-b531-1351d13cc1cd&_gl=1*1oqhlyy*_ga*MTI5MDk5OTI5Mi4xNjg4NTU2MzQw*_ga_CW55HF8NVT*MTY5NzE5NDU4OC40NS4xLjE2OTcxOTUwNTQuMzcuMC4w";
const map_book_pt = "https://firebasestorage.googleapis.com/v0/b/image-provider-b2b36.appspot.com/o/politica%2Fbook_dll%2Fmap_book_pt.png?alt=media&token=7a17d666-197c-481c-bac1-97ae4d70df95&_gl=1*1rc30k5*_ga*MTI5MDk5OTI5Mi4xNjg4NTU2MzQw*_ga_CW55HF8NVT*MTY5NzcyNTE0NS41Mi4xLjE2OTc3Mjk0MTIuNDQuMC4w";
const map_book_l_pt = "https://firebasestorage.googleapis.com/v0/b/image-provider-b2b36.appspot.com/o/politica%2Fbook_dll%2Fmap_book_l_pt.png?alt=media&token=9ce93ac6-f1be-471c-b563-ca27b6be870a&_gl=1*at85qv*_ga*MTI5MDk5OTI5Mi4xNjg4NTU2MzQw*_ga_CW55HF8NVT*MTY5NzcyNTE0NS41Mi4xLjE2OTc3Mjg3ODIuNDIuMC4w";
const map_en_s ="https://firebasestorage.googleapis.com/v0/b/image-provider-b2b36.appspot.com/o/map_en_s.png?alt=media&token=9aaa9744-e615-47c3-8d4e-f55958fcad4a&_gl=1*ilrnn3*_ga*MTI5MDk5OTI5Mi4xNjg4NTU2MzQw*_ga_CW55HF8NVT*MTY5NzE5NDU4OC40NS4xLjE2OTcxOTUyMTkuNjAuMC4w";
const map_en_l ="https://firebasestorage.googleapis.com/v0/b/image-provider-b2b36.appspot.com/o/map_en_l.png?alt=media&token=01336d98-1af4-44d3-8257-ee23bad0540b&_gl=1*18wm9ky*_ga*MTI5MDk5OTI5Mi4xNjg4NTU2MzQw*_ga_CW55HF8NVT*MTY5NzE5NDU4OC40NS4xLjE2OTcxOTUyNTYuMjMuMC4w";
const dll_discount_pt = "https://firebasestorage.googleapis.com/v0/b/image-provider-b2b36.appspot.com/o/politica%2Fbook_dll%2Fdll_discount_pt.png?alt=media&token=1b9b1702-c623-4340-be24-ac36726340b3&_gl=1*1fg1itw*_ga*MTI5MDk5OTI5Mi4xNjg4NTU2MzQw*_ga_CW55HF8NVT*MTY5NzcyNTE0NS41Mi4xLjE2OTc3MjY3NTkuMjkuMC4w";
const dll_discount_l_pt = "https://firebasestorage.googleapis.com/v0/b/image-provider-b2b36.appspot.com/o/politica%2Fbook_dll%2Fdll_discount_l_pt.png?alt=media&token=394daf7e-3a09-4e4e-bb0e-ca7486a4a86b&_gl=1*tp546a*_ga*MTI5MDk5OTI5Mi4xNjg4NTU2MzQw*_ga_CW55HF8NVT*MTY5NzcyNTE0NS41Mi4xLjE2OTc3MjczNTIuMzguMC4w";
const dll_discount_s_pt = "https://firebasestorage.googleapis.com/v0/b/image-provider-b2b36.appspot.com/o/politica%2Fbook_dll%2Fdll_discount_s_pt.png?alt=media&token=bd764631-d4c4-4646-8146-60764f811ed4&_gl=1*siz13a*_ga*MTI5MDk5OTI5Mi4xNjg4NTU2MzQw*_ga_CW55HF8NVT*MTY5NzcyNTE0NS41Mi4xLjE2OTc3MjkwMTAuNDUuMC4w";

const banner_chair = "https://firebasestorage.googleapis.com/v0/b/image-provider-b2b36.appspot.com/o/aethome%2Fbanner_chainr.png?alt=media&token=906e825f-4c44-4ed2-bd31-d14c53f48952&_gl=1*dqqkmc*_ga*MTI5MDk5OTI5Mi4xNjg4NTU2MzQw*_ga_CW55HF8NVT*MTY5ODMyNzQzNC42NC4xLjE2OTgzMjc0NTIuNDIuMC4w";
const banner_dll_pt = "https://firebasestorage.googleapis.com/v0/b/image-provider-b2b36.appspot.com/o/aethome%2Fbanner_dll_pt.png?alt=media&token=153a39ca-aea9-4bb1-95f1-eb177f38c468&_gl=1*1s17seo*_ga*MTI5MDk5OTI5Mi4xNjg4NTU2MzQw*_ga_CW55HF8NVT*MTY5ODY3OTc1Ni43MC4xLjE2OTg2ODA2NTMuNjAuMC4w";
const banner_dll_mobile_pt = "https://firebasestorage.googleapis.com/v0/b/image-provider-b2b36.appspot.com/o/aethome%2Fbanner_dll_mobile_pt.png?alt=media&token=892f405c-b072-4166-a427-b4aa2a6373ad";
const banner_pon_pt = "https://firebasestorage.googleapis.com/v0/b/image-provider-b2b36.appspot.com/o/aethome%2Fbanner_pon_pt.png?alt=media&token=c0d9b602-762d-442d-bf12-690d013fc648&_gl=1*1t99dfk*_ga*MTI5MDk5OTI5Mi4xNjg4NTU2MzQw*_ga_CW55HF8NVT*MTY5ODY3OTc1Ni43MC4xLjE2OTg2ODAyMjAuNDEuMC4w";
const banner_pon_mobile_pt = "https://firebasestorage.googleapis.com/v0/b/image-provider-b2b36.appspot.com/o/aethome%2Fbanner_pon_mobile_pt.png?alt=media&token=3f576bcd-e80f-49f7-aa4d-b029f850b766";
const banner_pon_1500_pt = "https://firebasestorage.googleapis.com/v0/b/image-provider-b2b36.appspot.com/o/aethome%2Fbanner_pon_1500_pt.png?alt=media&token=99343615-d105-4bc7-892d-d6a0bbce6ec4&_gl=1*htc8w9*_ga*MTI5MDk5OTI5Mi4xNjg4NTU2MzQw*_ga_CW55HF8NVT*MTY5OTM2NDY3My43NS4xLjE2OTkzNjYxNzAuNjAuMC4w";
const banner_pon_1500_en = "https://firebasestorage.googleapis.com/v0/b/image-provider-b2b36.appspot.com/o/banners%2Fbanner_pon_1500_en.png?alt=media&token=2c09e8ec-8183-4a20-a882-71ae81a6a157";
const banner_dll_1500_pt = "https://firebasestorage.googleapis.com/v0/b/image-provider-b2b36.appspot.com/o/aethome%2Fbanner_dll_1500_pt.png?alt=media&token=9ae0a860-58ae-40ec-ad69-6b71704796ca&_gl=1*uw79zw*_ga*MTI5MDk5OTI5Mi4xNjg4NTU2MzQw*_ga_CW55HF8NVT*MTY5OTM2NDY3My43NS4xLjE2OTkzNjYwNzYuMjkuMC4w";
const banner_dll_1500_en = "https://firebasestorage.googleapis.com/v0/b/image-provider-b2b36.appspot.com/o/banners%2Fbanner_dll_1500_en.png?alt=media&token=fdbe1079-9352-45fe-b325-cd766c5adf26";
const banner_trology_discount_1500_pt = "https://firebasestorage.googleapis.com/v0/b/image-provider-b2b36.appspot.com/o/banners%2Ftrilogy_discount_pt.png?alt=media&token=0bade31f-b87e-4899-925d-2950613787ef&_gl=1*6fcxoi*_ga*MTI5MDk5OTI5Mi4xNjg4NTU2MzQw*_ga_CW55HF8NVT*MTY5OTUyMzk2Ny44Mi4xLjE2OTk1MjQ0ODguNTMuMC4w";
const banner_trology_discount_1500_en = "https://firebasestorage.googleapis.com/v0/b/image-provider-b2b36.appspot.com/o/banners%2Fbanner_trology_discount_1500_en.png?alt=media&token=d9acfe14-539e-4122-be0c-617804ae89a5";
const banner_trology_discount2_1500_pt = "https://firebasestorage.googleapis.com/v0/b/image-provider-b2b36.appspot.com/o/banners%2Ftrilogy_discount2_pt.png?alt=media&token=9cd262f6-4b8a-4c87-bf32-af715e4b8702&_gl=1*1dg5m58*_ga*MTI5MDk5OTI5Mi4xNjg4NTU2MzQw*_ga_CW55HF8NVT*MTY5OTUyMzk2Ny44Mi4xLjE2OTk1MjU4OTEuMzkuMC4w";
const banner_trology_discount_map_1500_pt = "https://firebasestorage.googleapis.com/v0/b/image-provider-b2b36.appspot.com/o/banners%2Fbanner_trology_map_discount_1500_en.png?alt=media&token=713ba559-e742-496d-8f69-a7d4eee3838d";
const cover_trology_discount_pt = "https://firebasestorage.googleapis.com/v0/b/image-provider-b2b36.appspot.com/o/book_cover%2Ftrilogy_discount_pt.png?alt=media&token=e683646c-ad8a-4e39-9638-e3cbfbf0e3fc&_gl=1*c1isex*_ga*MTI5MDk5OTI5Mi4xNjg4NTU2MzQw*_ga_CW55HF8NVT*MTY5OTUyMzk2Ny44Mi4xLjE2OTk1MjU0NTEuMjcuMC4w";
const cover_trology_map_discount_en = "https://firebasestorage.googleapis.com/v0/b/image-provider-b2b36.appspot.com/o/book_cover%2Fcover_trology_map_discount_en.png?alt=media&token=c2480244-5d39-4087-8408-88276bae5637";
const cover_trology_discount_en = "https://firebasestorage.googleapis.com/v0/b/image-provider-b2b36.appspot.com/o/book_cover%2Fcover_trology_discount_en.png?alt=media&token=bd95f600-4f15-4ffd-907f-a1ee267da250";
const cover_dll_en = "https://firebasestorage.googleapis.com/v0/b/image-provider-b2b36.appspot.com/o/book_cover%2Fcover_dll_en.png?alt=media&token=d51ebdb4-9f24-4d12-81c2-6ea259248153";
const cover_pon_en = "https://firebasestorage.googleapis.com/v0/b/image-provider-b2b36.appspot.com/o/book_cover%2Fcover_pon_en.png?alt=media&token=19ef16c2-ed3f-4508-808f-8cbfb37da223";
const cover_pon_de = "https://firebasestorage.googleapis.com/v0/b/image-provider-b2b36.appspot.com/o/book_cover%2Fcover_pon_de.png?alt=media&token=20b6ec4d-f7e4-45b8-ac5d-fbf89bd9020c";
const cover_pon_manual_en = "https://firebasestorage.googleapis.com/v0/b/image-provider-b2b36.appspot.com/o/book_cover%2Fcover_pon_manual_en.jpg?alt=media&token=e6820673-408f-4e16-9ddf-3ef245f6d847";
const book_mockup_en = "https://firebasestorage.googleapis.com/v0/b/image-provider-b2b36.appspot.com/o/book_mockup%2Fbook_moclup_en.png?alt=media&token=3bd10b8d-ec7f-4f83-a17c-d7c17f39d3df";
const cover_echoes_en = "https://firebasestorage.googleapis.com/v0/b/image-provider-b2b36.appspot.com/o/book_cover%2Fcover_echoes_en.png?alt=media&token=29e9c09e-ca6c-4f7b-9154-df80f08a0533";
const cover_ecos_pt = "https://firebasestorage.googleapis.com/v0/b/image-provider-b2b36.appspot.com/o/book_cover%2Fcover_ecos_pt.png?alt=media&token=bb269210-f9dc-41e3-954b-341d9430768b";
const banner_ecos_pt = "https://firebasestorage.googleapis.com/v0/b/image-provider-b2b36.appspot.com/o/banners%2Fbanner-ecos-p1.png?alt=media&token=ce505a4c-af8d-4f60-a924-544b45df5fba";
const banner_echoes_en = "https://firebasestorage.googleapis.com/v0/b/image-provider-b2b36.appspot.com/o/banners%2Fbanner-echoes-p1.png?alt=media&token=7fb4d19f-463b-48e0-a74a-0e30b7c7d82b";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    locker,
    ssl,
    stamp,
    banner_chair,
    bookpt,
    phoneen,
    manualcoverpt,
    manualcoveren,
    backgroundsale,
    poncoverpt,
    triobookspt,
    map,
    dllpt,
    pon_pt_s,
    pon_manual_pt_s,
    map_book_pt,
    map_book_l_pt,
    map_pt_s,
    map_pt_l,
    map_en_s,
    map_en_l,
    dll_discount_pt,
    dll_discount_l_pt,
    dll_discount_s_pt,
    banner_dll_pt,
    banner_dll_mobile_pt,
    banner_pon_pt,
    banner_pon_mobile_pt,
    banner_pon_1500_pt,
    banner_pon_1500_en,
    banner_dll_1500_pt,
    banner_dll_1500_en,
    banner_trology_discount_1500_pt,
    banner_trology_discount_1500_en,
    banner_trology_discount2_1500_pt,
    banner_trology_discount_map_1500_pt,
    cover_trology_discount_pt,
    cover_trology_map_discount_en,
    cover_trology_discount_en,
    cover_dll_en,
    cover_pon_en,
    cover_pon_de,
    cover_pon_manual_en,
    book_mockup_en,
    cover_echoes_en,
    cover_ecos_pt,
    banner_echoes_en,
    banner_ecos_pt,
}