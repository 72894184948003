import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { AiFillStar, AiTwotoneFilePdf } from "react-icons/ai"
import { BsBookHalf } from "react-icons/bs"
import { TbWorldQuestion } from "react-icons/tb"
import { FiAtSign } from "react-icons/fi"
import img from '../../img/img';
import FormatButton from '../common/FormatButton';
import BuyBTN from '../common/BuyBTN';
import languageHome from '../../locales/home/language-home';
import { myContext } from '../../context/ContextProvider';
import Accordion from 'react-bootstrap/Accordion';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import SocialSharing from '../common/SocialSharing'
// import { Link } from 'react-router-dom'
import SslSecureBadge from '../common/SslSecureBadge'
import PDFView from '../common/PDFView'


export default function ShopPagev2({ obj }) {
    const { Language, setShowBookPreview, ShowBookPreview, setPdfLink, pdfLink } = useContext(myContext);

    const toggleBookPreview = (pdf_link) => {
        setPdfLink(pdf_link)
        setShowBookPreview(true)
    }

    // // Adicione o script à página
    // useEffect(() => {
    //     const script = document.createElement('script');
    //     script.innerHTML = `
    //         pintrk('track', 'checkout', {
    //         event_id: 'eventId0001',
    //         value: 100,
    //         order_quantity: 1,
    //         currency: 'USD'
    //         });
    //     `;
    //     document.body.appendChild(script);

    //     // Limpe o script quando o componente for desmontado
    //     return () => {
    //         document.body.removeChild(script);
    //     };
    // }, []);


    return (
        <>
            <ShopPageContainer>
                <div className='shoppage-product-container'>
                    <Breadcrumb>
                        <Breadcrumb.Item href='/'>
                        {/* <Link to="/" >
                        </Link> */}
                        Home
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>
                        {/* <Link to="/collections" >
                            {languageHome[Language]?.collection?.bradcrumb}
                        </Link> */}
                            {languageHome[Language]?.collection?.bradcrumb}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <div className='product-header'>
                        <div
                            onClick={() => toggleBookPreview(
                                obj.link_preview
                            )}

                            className='image-container'
                        >
                            <img src={img[obj.image]} alt="" />
                            {
                                obj.link_preview
                                !== "" &&
                                <p>{languageHome[Language].collection.preview_image}</p>
                            }
                        </div>
                        <div className='content-container'>
                            <p className='author font-face-poppins-regular'>{obj.author}</p>
                            <p className='title font-face-poppins-bold'>{obj.title}</p>
                            <p className='subtitle font-face-poppins-regular'>{obj.subtitle}</p>
                            <AiFillStar className='star' />
                            <AiFillStar className='star' />
                            <AiFillStar className='star' />
                            <AiFillStar className='star' />
                            <AiFillStar className='star' />
                            <p className='published font-face-GlacialIndifference'>{obj.version}</p>
                            <p className='published font-face-GlacialIndifference'>{obj.language}</p>
                            <p className='published font-face-GlacialIndifference'>{obj.published}</p>
                            <p className='deliver font-face-GlacialIndifference'>{obj.deliver}</p>
                            <div className='formats'>
                                <p className='title-formats font-face-GlacialIndifference'>{obj.formats.info}</p>
                                <FormatButton arr={obj.formats.arr} />
                            </div>
                            <Accordion className='mt-5 accordion' defaultActiveKey="0" flush>
                                <Accordion.Item eventKey="0" >
                                    <Accordion.Header >{languageHome[Language]?.collection?.description}</Accordion.Header>
                                    <Accordion.Body >
                                        <p className='description font-face-GlacialIndifference'>
                                            {obj.description}
                                        </p>

                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>{languageHome[Language]?.collection?.details}</Accordion.Header>
                                    <Accordion.Body>
                                        <h1 className='font-face-GlacialIndifference'>{obj.info.description}</h1>
                                        <h2 className='font-face-GlacialIndifference'>{obj.info.descriptionSub}</h2>
                                        <p className='font-face-GlacialIndifference'>{obj.info.descriptionText}</p>
                                        <h3 className='mt-4 font-face-GlacialIndifference'>
                                            {languageHome[Language].collection.info.info}
                                        </h3>
                                        <p className='font-face-GlacialIndifference'>
                                            {languageHome[Language].collection.info.info.infoText}
                                        </p>
                                        <h4 className='mt-4 font-face-GlacialIndifference'>
                                            {languageHome[Language].collection.info.format}
                                        </h4>
                                        <p className='font-face-GlacialIndifference'>
                                            {languageHome[Language].collection.info.formatText}
                                        </p>
                                        <h5 className='mt-4 font-face-GlacialIndifference'>
                                            {languageHome[Language].collection.info.detail}
                                        </h5>
                                        <p className='font-face-GlacialIndifference'>
                                            {languageHome[Language].collection.info.detailText}
                                        </p>
                                        <h6 className='mt-4 font-face-GlacialIndifference'>
                                            {languageHome[Language].collection.info.transfer}
                                        </h6>
                                        <p className='font-face-GlacialIndifference'>
                                            {languageHome[Language].collection.info.transferText}
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                        <div className='price-container'>
                            {/* <AiOutlineShoppingCart className='icon-cart' /> */}
                            {
                                obj.pricerisk !== "" &&
                                <p className='price-text-risked font-face-poppins-bold'>{obj.pricerisk}</p>
                            }
                            <p className='price-text font-face-poppins-bold'>{obj.price}</p>
                            <BuyBTN text={languageHome[Language].shopcart.button} link={obj.button.link} />
                            <div className='protected-wraper'>
                                <div className='ball'>
                                    <img src={img.locker} alt="" />
                                </div>
                                <p className='ball-text'>{languageHome[Language].collection.available}</p>
                            </div>
                            <SslSecureBadge />
                            <SocialSharing />
                        </div>
                    </div>
                    <div className='middle-container'>
                        <div className='bibliographie'>
                            <div className='biblio-item'>
                                {languageHome[Language].collection.biblio.pages}
                                <div className='biblio-icon'>
                                    <BsBookHalf />
                                </div>
                                <p className='biblio-text font-face-GlacialIndifference'>{obj.pages}</p>
                            </div>
                            <div className='biblio-item'>
                                {languageHome[Language].collection.biblio.language}
                                <div className='biblio-icon'>
                                    <TbWorldQuestion />
                                </div>
                                <p className='biblio-text font-face-GlacialIndifference'>{obj.language}</p>
                            </div>
                            <div className='biblio-item'>
                                {languageHome[Language].collection.biblio.publisher}
                                <div className='biblio-icon'>
                                    <FiAtSign />
                                </div>
                                <p className='biblio-text font-face-GlacialIndifference'>{obj.publisher}</p>
                            </div>
                            {/* <div className='biblio-item'>
                                {languageHome[Language].collection.biblio.isbn}
                                <div className='biblio-icon'>
                                    <AiOutlineBarcode />
                                </div>
                                <p className='font-face-prata'>{obj.isbn}</p>
                            </div> */}
                            <div className='biblio-item'>
                                {languageHome[Language].collection.biblio.version}
                                <div className='biblio-icon'>
                                    <AiTwotoneFilePdf />
                                </div>
                                <p className='biblio-text font-face-GlacialIndifference'>{obj.version}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </ShopPageContainer >
            {ShowBookPreview && <PDFView prop={pdfLink} />
            }
        </>
    )
}

const ShopPageContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    .shoppage-product-container{
        
        margin-top: 3rem;
        width: 1320px;
        
        height: 50%;
        .product-header{
            
            display: flex;
            
            justify-content: space-around;
            .image-container{
                cursor: pointer;
                width: 320px;
                position: relative;
                
                img{
                    
                    width: 100%;
                    -webkit-box-shadow: 0px 31px 37px -5px rgba(0,0,0,0.47); 
                    box-shadow: 0px 31px 37px -5px rgba(0,0,0,0.47);
                }
                /* :hover{
                    transform: scale(1.1);
                    transition: 1s ease-in-out;
                } */
            }
            .content-container{
                width: 50%;
                padding-left: 1rem;
                .author{
                    font-weight: 600;
                }
                .title{
                    font-size: 1.7rem;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    color: black;
                    font-weight: bold;
                }
                .subtitle{
                    font-size: 1rem;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                }
                .star{
                    color: #ffcc00;
                    font-size: 1.6rem;
                    margin-top: 1rem;
                }
                
                .published{
                    margin-top: 1rem;
                    font-weight: 600;
                }
                .deliver{
                    font-weight: 600;
                }
                
                .description{
                    margin-top: 1rem;
                    font-weight: 600;
                    letter-spacing: 2px;
                }
                
                p{
                    word-wrap: break-word;
                    word-break: break-word;
                    text-align: justify ;
                    span{
                        font-weight: bold;
                    }
                    margin: 0;
                    padding: 0;
                }
                .formats{
                    /* border: #7bff00 solid 2px; */
                    margin-top: 1rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    
                    .title-formats{
                        font-size: 1rem;
                        padding-bottom: 1rem;
                        /* margin-bottom: 3rem; */
                        text-transform: uppercase;
                    }   
                }
                h1, h2, h3, h4, h5, h6{
                    font-size: 1.3rem;
                }
            }
            .price-container{
                display: flex;
                align-items: center;
                flex-direction: column;
                .price-text-risked{
                    text-decoration: line-through;
                    color: #ff000090;
                }
                .price-text{
                    color: #1d1d1d;
                    font-size: 2.1rem;
                }
                .icon-cart{
                    font-size: 2rem;
                    /* border: black solid 2px; */
                    width: 100%;
                }
                p{
                    padding: 1rem;
                    font-size: 2.5rem ;
                    font-weight: bold;
                    color: #4f4f4f;
                }
                .protected-wraper{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 25px;
                    width: 300px;
                    height: 100px;
                    .ball{
                        /* border: black 1px solid; */
                        height: 45px;
                        width: 45px;
                        /* border-radius: 50%; */
                        /* background-color: #00ff00; */
                        padding: .4rem;
                        img{
                            height: 35px;
                            width: 35px;
                        }
                    }
                    .ball-text{
                        font-size: 1rem;
                        margin: 0;
                        padding: 0;
                        padding-left: 1rem;
                    }
                }
            }
            @media screen and (max-width: 768px) {
                flex-direction: column; 
                .content-container {
                    width: 100%;
                }
                /* .price-container {
                    .icon-cart{
                        display: none;
                    }
                } */
            }                 
        }
        .middle-container {
            /* border: #001aff solid 2px; */
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 3rem;
            .bibliographie  {
                border-top: #55555541 solid 1px;
                border-bottom: #55555541 solid 1px;
                padding: 2rem;
                display: flex;
                justify-content: center;
                gap: 5rem;
                width: 100%;
                .biblio-item{
                    width: 100px;
                    text-align: center;
                    font-size: .8rem;
                    text-transform: uppercase;
                    .biblio-icon{
                        font-size: 1.8rem;
                    }
                    .biblio-text{
                        font-size: .8rem;
                    }
                }
            }
            @media screen and (max-width: 1079px) {
                /* justify-content: space-around; */
                .bibliographie{
                    margin-left: 0rem;
                    flex-wrap: wrap;
                    /* width: 35%; */
                    justify-content: space-between;
                    gap: 0;
                }
            }      
        }
        
    }
    
    @media screen and (max-width: 1079px) {
        .shoppage-product-container {
            margin-top: 1rem; // Reduzir margem
            padding: 0 15px; // Adicionar padding nas laterais

            .product-header {
                flex-direction: column;
                align-items: center;

                .image-container {
                    cursor: pointer;
                    @media screen and (max-width: 768px) {
                        width: 50%; // Tornar a imagem mais responsiva
                    }
                    width: 30%; // Tornar a imagem mais responsiva
                    margin: 0 auto; // Centralizar
                }

                .content-container {
                    width: 100%; // Aproveitar toda a largura disponível
                    padding: 1rem 0; // Ajustar padding
                    .title{
                        text-align: center;
                        font-size: 1.3rem; // Reduzir tamanho da fonte
                    }
                    .author, .subtitle, .published, .deliver, .description {
                        /* text-align: center; */
                        font-size: 1.1rem; // Reduzir tamanho da fonte
                    }
                    .star {
                        font-size: 1.4rem; // Ajustar ícones de estrela
                    }
                    .formats .title-formats {
                        font-size: 0.9rem; // Ajustar título dos formatos
                    }
                }

                .price-container {
                    width: 100%; // Usar toda a largura
                    text-align: center; // Centralizar conteúdo
                    .price-text {
                        font-size: 1.7rem; // Reduzir tamanho da fonte do preço
                    }
                    .protected-wraper {
                        justify-content: center; // Centralizar conteúdo
                        .ball-text {
                            font-size: 0.8rem; // Reduzir tamanho da fonte
                        }
                    }
                }
            }

            /* .middle-container {
                flex-direction: column; // Dispor em coluna para melhor visualização
                .bibliographie {
                    flex-wrap: wrap; // Permitir quebra de linha
                    flex-flow: column wrap;
                    justify-content: space-between; // Melhor distribuição
                    .biblio-item {
                        width: 45%; // Ajustar largura para quebrar a linha
                        margin-bottom: 1rem; // Espaçamento entre itens
                    }
                }
            } */
        }
    }
`;