import React from 'react'
import styled from 'styled-components'
import img from '../../../img/img';
import GoldButton from '../../common/GoldButton';

export default function OtherBooks({obj}) {

    return (
        <>
            <BonusSection className='bonus'>
                <h1 className='font-face-BodoniflfBold'>
                {obj.copytitle}
                </h1>
                <div className='books'>
                    <img
                        className='book-cover'
                        src={img[obj.img]} alt="Book"
                    />
                </div>
                <div className='bonus-copy'>
                    {/* <h1 className='font-face-BodoniflfBold'>
                        {obj.copytitle}
                    </h1> */}
                    <h2 className='font-face-Kollektif-BoldItalic'>
                        {obj.subtitle}
                    </h2>
                    <h3 className='font-face-BodoniflfItalic'>
                        {obj.copy}
                    </h3>
                </div>
                <GoldButton text={obj.button.text} link={obj.button.link} />
            </BonusSection>
        </>
    )
}

const BonusSection = styled.section`
    /* border: red 2px solid; */

    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10rem;
    background-color: #fafafa;
    height: 800px;
    width: 668px;
    box-shadow: inset -1px 3px 8px 5px #edeeef, 0px 12px 29px -10px #AA8658, 0px 2px 50px 15px rgba(0,0,0,0);
    border-radius: 10px;
    h1{
        font-size: 2rem;
        padding: 4%;
        text-transform: capitalize;
    }
    .books {
        background-color: #aa875826;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50%;
        width: 100%;
        .book-cover{
            height: 75%;
        }
        .phone {
            height: 50%;
        }
    }
    .bonus-copy {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80%;
        h1{
            font-size: 2.3rem;
        }
        h2{
            font-size: 1rem;
            text-transform: uppercase;
            letter-spacing: 4px;
            text-align: center;
        }
        h3{
            padding: 3%;
            text-align: center;
            letter-spacing: 2px;
            line-height: 1.4rem;
        }
    }
    
    @media screen and (min-width: 280px) and (max-width: 1080px) {
        margin-left: 1rem;
        margin-right: 1rem;
        height: 768px;
        width: auto;
    }
`;
