import React, { useContext } from 'react'
import styled from 'styled-components'
import Navbar from './NavBar';
import languageHome from '../../locales/home/language-home';
import { myContext } from '../../context/ContextProvider';
import Accordion from 'react-bootstrap/Accordion';

export default function FAQ({ faq }) {
    const { Language } = useContext(myContext);
    return (
        <>
            <FAQComponent>
                <Navbar navbar={languageHome[Language].navbar} />
                <div className="faq-section">
                    <h2 className='text-center'>{languageHome[Language].faq.title}</h2>
                    {
                        languageHome[Language].faq.arr.map((item, index) => (
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>{item.question}</Accordion.Header>
                                    <Accordion.Body>{item.answer}</Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        ))
                    }
                </div>
            </FAQComponent>
        </>
    )
}

const FAQComponent = styled.div`

`;
