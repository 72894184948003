import React from 'react'
import styled from 'styled-components'

export default function Logo() {
    return (
        <LogoComponent>
            <Shield>
                <div className='banner-title'>
                    <span className='font-face-allura'>a</span>
                    <span className='span-e font-face-allura'>&</span>
                    <span className='font-face-allura'>t</span>
                </div>
                <p className='span-tales font-face-lora '>tales</p>
            </Shield>
        </LogoComponent>
    )
}

const LogoComponent = styled.div`
    width: 100%;
    /* position: absolute; */
    /* top: 7rem; */
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    .banner-title {
        /* border: #000000 solid 1px; */
        margin-left: -3rem;
        margin-top: 2rem;
        padding: 0;
            span{
                /* letter-spacing: 6px; */
                text-transform: uppercase;
                color: #a68e45;
                font-size: 5.5rem;
                font-weight: bold;
                text-shadow: 2px 3px 8px rgba(0,0,0,1);
            }
            .span-e{
                text-shadow: 2px 3px 8px rgba(0,0,0,0.66);
                /* letter-spacing: 9px; */
                color: #2f2323;
                font-size: 3rem;
            }
        
    }
    .span-tales{
        /* position: absolute; */
        font-weight: bold;
        letter-spacing: 16px;
        text-transform: uppercase;
        color: #2f2323;
        font-size: 2rem;
        margin-left: 1rem;
    }  
`;

const Shield = styled.div`
    width: 260px;
    height: 260px;
    background-color: #EBE7E3;
    /* background-color: #edebe9; */
    border-radius: 50%;
    -webkit-box-shadow: inset 0px 3px 8px 5px #dbdbdb, 0px 5px 16px 0px #ebebeb, 0px 2px 50px 15px rgba(0,0,0,0); 
        box-shadow: inset 0px 3px 8px 5px #edeeef, 0px 12px 29px -10px #ffffff, 0px 2px 50px 15px rgba(0,0,0,0);
    
`;



// export default function Logo() {
//     return (
//         <LogoComponent>
//             <p className='banner-title font-face-quattocento'>
//                 <span className='font-face-allura'>A</span>
//                 <span className='font-face-allura'>&</span>
//                 <span className='font-face-allura'>T</span>
//             </p>
//             <p className='span-tales font-face-lora '>Tales</p>
//         </LogoComponent>
//     )
// }

// const LogoComponent = styled.div`
//     width: 100%;
//     text-align: center;

//     .banner-title {
//         margin-top: 0.5rem;
//         span {
//             text-transform: uppercase;
//             color: #a68e45;
//             font-size: 4.5rem;
//             font-weight: bold;
//             text-shadow: 2px 3px 8px rgba(0, 0, 0, 0.8);
//             margin: 0 5px;
//             padding: 0 5px;
//         }
//     }

//     .span-tales {
//         font-weight: bold;
//         letter-spacing: 2px;
//         text-transform: uppercase;
//         color: #2f2323;
//         font-size: 1.5rem;
//     }
// `;
