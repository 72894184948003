// eslint-disable-next-line import/no-anonymous-default-export
export default {
    navbar: {
        logo: "",
        linksArr: [
            {
                text: "Home",
                link: "/",
                icon: "BiHomeAlt",
            },
            {
                text: "Bücher",
                link: "/collections",
                icon: "MdOutlineCollectionsBookmark",
            },
            {
                text: "Kontakt",
                link: "/contact",
                icon: "MdOutlineAlternateEmail",
            },
            {
                text: "FAQ",
                link: "/faq",
                icon: "FaQuestionCircle",
            },
        ]
    },
    home: {
        welcome: {
            text1: "Willkommen",
            text2: "Auf jeder Seite ein neuer Horizont. Begeben Sie sich auf unvergessliche Reisen"
        },
        button: {
            text: "Bücher",
            link: "/collections",
        }
    },
    newsletter:{
        title: "Abonniere unseren Newsletter",
        email: "Geben sie ihre E-Mail Adresse ein",
        button: "Abonnieren",
        manage: "Um Ihr Abonnement zu verwalten, klicken Sie auf",
        here: "managen",
        unsub: "Melden Sie sich von unserem Newsletter ab",
        emailunsub:"Geben Sie Ihre E-Mail-Adresse ein, um sich abzumelden",
        buttonunsub: "Abbestellen"
    },
    contact: {
        title: "Kontaktiere uns!",
        text: "Wir bei A&T Tales sind immer bereit, von Ihnen zu hören. Ob es eine Frage zu unseren Büchern ist, eine Frage zu Ihrer Bestellung oder sogar Feedback zu Ihrer Lektüre, Ihre Stimme ist uns wertvoll.",
        text2: "Füllen Sie das untenstehende Formular aus oder schreiben Sie uns direkt an info@aettales.com. Wir versprechen, so schnell wie möglich mit all der Aufmerksamkeit zu antworten, die Sie verdienen.",
        text3: "Für Unterstützung oder Fragen nach dem Kauf, bitte kontaktieren Sie uns unter contact@aettales.com.",
        text4: "Danke, dass Sie sich für A&T Tales entschieden haben, wo jede Geschichte eine neue Reise ist!",
        label: "Email: ",
        label2: "Name: ",
        label3: "Betreff: ",
        label4: "Nachricht: ",
        label5: "Schicken",
        email: "info@aettales.com",
        error: 'Fehler beim Senden der Nachricht. Versuchen Sie es später noch einmal.',
        aftersales: {
            label2: "Geburtsname: ",
            label3: "Geburtsdatum: ",
            title: "Danke für Ihren Kauf!",
            text: "Liebe Leserin, lieber Leser, ",
            text2: "Wir freuen uns, den Erhalt Ihrer Bestellung zu bestätigen! Wir sind begeistert, dass Sie A&T Tales ausgewählt haben, um Sie auf den nächsten Seiten von Abenteuer und Wissen zu begleiten.",
            text3: "Der nächste Schritt, um Ihre numerologische Karte zu erhalten, besteht darin, uns Ihre Informationen zu senden. Bitte füllen Sie das untenstehende Formular mit Ihrem Geburtsnamen, Geburtsdatum und der bei der Kaufregistrierung angegebenen E-Mail aus. Sobald wir Ihre Daten erhalten haben, beginnen wir mit der Erstellung Ihrer persönlichen Karte. Die geschätzte Fertigstellungszeit beträgt 3 Werktage. Wir werden eine E-Mail mit einem Link zum Herunterladen der Datei senden.",
            text4: "Irgendwelche Fragen?",
            text5: "Wir sind hier, um zu helfen. Wenn Sie Fragen haben oder Hilfe benötigen, können Sie uns gerne über den Kontaktlink auf der Seite kontaktieren.",
            text6: "Genießen Sie die Lektüre und möge sie nur der Anfang einer langen Geschichte zwischen Ihnen und A&T Tales sein.",
            text7: "Mit Wertschätzung,",
            text8: "Das A&T Tales-Team",
            text9: "Attention",
        }
    },
    faq: {
        title: "FAQ - Häufig gestellte Fragen",
        arr: [
            {
                question: "Wie kann ich ein Buch kaufen?",
                answer: "Sie können unsere Bücher direkt auf unserer Website kaufen. Wählen Sie das Buch, das Sie möchten, fügen Sie es dem Warenkorb hinzu und gehen Sie zur Kasse, um Ihren Kauf abzuschließen."
            },
            {
                question: "Welche Zahlungsmethoden werden akzeptiert?",
                answer: "Wir akzeptieren verschiedene Zahlungsmethoden, einschließlich Kreditkarte, PayPal und Pix. Wählen Sie die bevorzugte Methode zum Zeitpunkt der Zahlung aus."
            },
            {
                question: "In welchen Formaten sind die Bücher verfügbar?",
                answer: "Unsere Bücher sind in Hardcover, Paperback und digitalen (eBook) Formaten verfügbar. Auf der Seite jedes Buches finden Sie die verfügbaren Formatoptionen."
            },
            {
                question: "Wie kann ich das eBook nach dem Kauf zugreifen?",
                answer: "Nach dem Kauf können Sie das eBook sofort über den bereitgestellten Link auf der Seite herunterladen, und es wird in Ihrem Whop-Konto verfügbar sein."
            },
            {
                question: "Ist es sicher, auf der Website zu kaufen?",
                answer: "Unser Zahlungsanbieter Whop und Amazon verwenden SSL-Verschlüsselungstechnologie, um Ihre persönlichen und finanziellen Daten zu schützen. Ihre Sicherheit ist unsere Priorität."
            },
            {
                question: "Kann ich ein Buch zurückgeben, wenn ich nicht zufrieden bin?",
                answer: "Für physische Bücher kontaktieren Sie bitte direkt Amazon.de, wo Sie den Kauf getätigt haben. Für eBooks kontaktieren Sie uns über das Kontaktformular oder per E-Mail an info@aettales.com und fordern Sie innerhalb von 14 Tagen nach dem Kauf eine Rückerstattung an."
            },
            {
                question: "Wie kann ich Sie kontaktieren, wenn ich weitere Fragen habe?",
                answer: "Wenn Sie weitere Fragen haben, können Sie uns über unsere Kontaktseite oder per E-Mail an info@aettales.com kontaktieren. Unser Team ist bereit, Ihnen zu helfen!"
            },
        ]
    },
    carousel: [
        // {
        //     image: "banner_trology_discount_1500_en",
        //     imagemob: "dll_discount_pt",
        //     text: "Entdecken Sie die Geheimnisse der Zahlen und heben Sie Ihre Entwicklung auf die nächste Stufe. Erforschen Sie die Macht der Zahlen und verwandeln Sie Ihr Leben.",
        //     title: "Die Macht der Numerologie",
        //     link: "/collections/numerology-trilogy?category=esoteric"
        // },
        // {
        //     image: "banner_trology_discount_map_1500_pt",
        //     imagemob: "cover_trology_discount_pt",
        //     text: "Entdecken Sie die Geheimnisse der Zahlen und heben Sie Ihre Entwicklung auf die nächste Stufe. Erforschen Sie die Macht der Zahlen und verwandeln Sie Ihr Leben.",
        //     title: "Die Macht der Numerologie",
        //     link: "/collections/numerology-trilogy?category=esoteric"
        // },
        {
            image: "banner_echoes_en",
            imagemob: "banner_echoes_en",
            text: "",
            title: "Echoes of 3077",
            link: "/collections/echoes-of-3077?category=science-fiction"
        },
        {
            image: "banner_dll_1500_en",
            imagemob: "banner_dll_mobile_pt",
            text: "Entdecken Sie die Geheimnisse der Zahlen und heben Sie Ihre Entwicklung auf die nächste Stufe. Erforschen Sie die Macht der Zahlen und verwandeln Sie Ihr Leben.",
            title: "Die Sprache der Liebe entschlüsseln",
            link: "/deciphering-the-language-of-love"
        },
        {
            image: "banner_pon_1500_en",
            imagemob: "banner_pon_mobile_pt",
            text: "Entdecken Sie die Geheimnisse der Zahlen und heben Sie Ihre Entwicklung auf die nächste Stufe. Erforschen Sie die Macht der Zahlen und verwandeln Sie Ihr Leben.",
            title: "Die Macht der Numerologie",
            link: "/collections/the-power-of-numerology?category=esoteric"
        },
    ],
    shopcart: {
        button: "Kaufen",
        otherItems: {
            title: "EMPFOHLENE ARTIKEL",
            arr: [
                {
                    title: "",
                    item: "Echoes of 3077 - The Awakening - Part I",
                    format: "E-Book - Download",
                    img: "cover_echoes_en",
                    price: "$ 14,99",
                    link: "/collections/echoes-of-3077?category=science-fiction",
                },
                // {
                //     title: "",
                //     item: "Numerological trilogy + personalized numerological map + app",
                //     format: "E-Book - Download",
                //     img: "cover_trology_map_discount_en",
                //     price: "$ 102,97",
                //     link: "/collections/deciphering-the-language-of-love_discount?category=esoteric",
                // },
                // {
                //     title: "",
                //     item: "Numerological trilogy + app",
                //     format: "E-Book - Download",
                //     img: "cover_trology_discount_en",
                //     price: "$ 25,97",
                //     link: "/collections/numerology-trilogy?category=esoteric",
                // },
                // {
                //     title: "",
                //     item: "PERSONALIZED numerological map",
                //     format: "E-Book - Download",
                //     img: "map_en_s",
                //     price: "$ 220,00",
                //     link: "/collections/numerological_map?category=esoteric",
                // },
                {
                    title: "",
                    item: "Die Macht der Numerologie - Die Geheimnisse des Erfolgs enthüllen",
                    format: "E-Book - Download",
                    img: "cover_pon_de",
                    price: "14,99 €",
                    link: "/collections/the-power-of-numerology?category=esoteric",
                },
                {
                    title: "",
                    item: "DECIPHERING THE LANGUAGE OF LOVE + BONUS",
                    format: "E-Book - Download",
                    img: "cover_dll_en",
                    price: "$ 14,99",
                    link: "/collections/deciphering-the-language-of-love?category=esoteric",
                }
            ],
        },
    },
    collection: {
        section_title: "eBücher",
        add: "In den Warenkorb",
        available: "Sicherer Kauf | SSL-Verschlüsselung | Dienstleister Whop",
        recomend: "Empfehlen",
        showmore: "Zeig mehr",
        example: "Leseprobe",
        pages: "Seitenzahl",
        language: "Sprache",
        editor: "Verlag",
        isbn: "ISBN-13",
        more: "Mehr von ",
        bradcrumb: "eBücher",
        description: "Beschreibung",
        details: "Einzelheiten",
        preview_image: "KLICKEN SIE FÜR VORSCHAU",
        sicialshare: "Aktie",
        collection_title: {
            esoteric: "Esoterische eBücher",
            scifi: "Science-Fiction"
        },
        biblio: {
            pages: "Seiten",
            language: "Sprache",
            version: "version",
            publisher: "Verlag",
            isbn: "ISBN",
        },
        info: {
            info: "Informationen über E-Books",
            infoText: "Unsere E-Books werden sorgfältig gestaltet, um Ihnen ein fesselndes und zugängliches Leseerlebnis zu bieten. Um sicherzustellen, dass Sie das Beste aus Ihrer digitalen Bibliothek herausholen, finden Sie hier einige wichtige Details:",
            format: "Dateiformat: EPUB",
            formatText: "Alle unsere E-Books sind im EPUB-Dateiformat verfügbar. Das bedeutet, dass Sie sie auf einer Vielzahl von Geräten lesen können, einschließlich Ihres Computers, Tablets, Smartphones oder E-Readers. Die Einfachheit des EPUB-Formats ermöglicht es Ihnen, Ihre Lektüre überall und jederzeit zu genießen, ohne dass spezielle Software erforderlich ist.",
            detail: "Formatdetails und Schutz",
            detailText: "In der Detailansicht jedes Titels finden Sie spezifische Informationen zum Format des E-Books und zum Kopierschutz, sofern zutreffend. Unsere Priorität ist es, sicherzustellen, dass Sie alle notwendigen Informationen haben, um Ihre Lektüre ohne Komplikationen genießen zu können.",
            transfer: "Einfache Übertragung auf Ihr Gerät",
            transferText: "Das Übertragen unserer E-Books auf Ihr Gerät ist unkompliziert. E-Books ohne Kopierschutz oder mit digitalem Wasserzeichen können problemlos auf Ihr Gerät übertragen werden, was ein reibungsloses Leseerlebnis gewährleistet. Stellen Sie sicher, dass Sie die Kompatibilität mit Ihrem Gerät überprüfen, um einen nahtlosen Übergang zu gewährleisten."
        }
    },
    footer: [
        {
            link: "/contact",
            text: "Kontakt",
        },
        {
            link: "/privacy",
            text: "Datenschutz und Nutzungsbedingungen",
        },
        {
            text: "FAQ",
            link: "/faq"
        },
        {
            text: "Impressum",
            link: "/impressum"
        },
        {
            text: "Newsletter",
            link: "/newsletter"
        }
    ],
    terms: {
        text: "Diese Website verwendet Google Analytics, um Analyse-Daten zu sammeln und das Benutzererlebnis zu verbessern. Diese Informationen werden anonym gesammelt und umfassen Daten wie geografischer Standort, verwendetes Gerät, besuchte Seiten und Verweildauer auf der Seite. Indem Sie auf dieser Seite weitersurfen, stimmen Sie der Sammlung und Verarbeitung dieser Daten durch Google Analytics zu. Weitere Informationen darüber, wie Google Analytics Daten sammelt und verarbeitet, finden Sie in unserer Datenschutzrichtlinie.",
        title: "Datenschutz und Nutzungsbedingungen",
        link: "https://firebasestorage.googleapis.com/v0/b/image-provider-b2b36.appspot.com/o/politica%2FPoli%CC%81tica%20de%20Privacidade.pdf?alt=media&token=7153f364-be29-4823-9e19-778356331fbd&_gl=1*55yrzm*_ga*MTI5MDk5OTI5Mi4xNjg4NTU2MzQw*_ga_CW55HF8NVT*MTY5NzcwOTkxOC41MC4xLjE2OTc3MDk5NjQuMTQuMC4w",
    },
    compliance: {
        termsOfServiceTitle: "Nutzungsbedingungen",
        termsOfServiceText: "Durch die Verwendung dieser App stimmen Sie unseren Nutzungsbedingungen zu.",
        privacyPolicyTitle: "Datenschutzrichtlinie",
        privacyPolicyText: "Wir sind verpflichtet, Ihre Privatsphäre zu schützen. Lesen Sie unsere Datenschutzrichtlinie für weitere Informationen."
    },
}