import React, { useContext } from 'react'
import styled from 'styled-components'
import hero from "../../../img/dll/HeroDLL.png"
import { myContext } from '../../../context/ContextProvider'
import languageDll from '../../../locales/books/dll/language-dll'
import Carrousel from './Carroussel'

export default function Hero() {
  const { Language } = useContext(myContext);

  return (
    <>
      <HeroSection className='hero-section'>
        <div className='background'></div>
        <Carrousel arr={languageDll[Language].carrousel} /> 
      </HeroSection>
    </>
  )
}

const HeroSection = styled.section`
  position: relative;
  width: 100%;
  height: 60vh;
  
  .background {
    height: 100%;
    background-image: url(${hero});
    background-size: cover;
    background-position: center 40%;
    filter: opacity(60%);
  }
  
    @media screen and (min-width: 280px) and (max-width: 1079px) {
      /* border: 2px solid yellow; */
      height: 100vh;
  } 
`;