import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Main from "./components/home/Main";
import MainDLL from "./components/books/dll/MainDLL";
import ScrollReveal from "scrollreveal";
import ContextProvider from "./context/ContextProvider";
import ShopingCart from "./components/home/ShopingCart";
import ErrorPage from "./components/common/ErrorPage";
import PrivacyTerms from "./components/common/PrivacyTerms";
import Collections from "./components/home/Collections";
import FAQ from "./components/common/FAQ";
// import WhopRedirect from "./components/common/WhopRedirect";
import 'bootstrap/dist/css/bootstrap.min.css';
import ContactPage from "./components/common/ContactPage";
import Aditionalinfo from "./components/home/Aditionalinfo";
import Impressum from "./components/home/Impressum";
import NewsletterSignup from "./components/home/NewsletterSignup";
import NewsletterUnsubscribe from "./components/home/NewsletterUnsubscribe";


function App() {

  useEffect(() => {
    const sr = ScrollReveal({
      origin: "top",
      distance: "80px",
      duration: 2000,
      reset: true,
    });
    sr.reveal(
      `
        nav,
        .hero-section,
        .testemony-section,
        .bonus,
        .books,
        .offer,
        .inscrevase
        `,
      {
        opacity: 0,
        interval: 300,
      }
    );
  }, []);
  return (
    <div className="App">
        <ContextProvider>
          <Router>
            <Routes>
              <Route path="/" element={<Main />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/privacy" element={<PrivacyTerms />} />
              <Route path="/collections" element={<Collections />} />
              <Route path="/collections/:id" element={<ShopingCart />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/impressum" element={<Impressum />} />
              <Route path="/newsletter" element={<NewsletterSignup />} />
              <Route path="/newsletterunsubscribe" element={<NewsletterUnsubscribe />} />
              <Route path="/contact/aditional-info/:id" element={<Aditionalinfo />} />
              {/* <Route path="/calback/whop" element={<WhopRedirect />} /> */}
              <Route path="/404" element={<ErrorPage />} /> 
              <Route path="*" element={<ErrorPage />} />     
              
              {/* Books Routes */}
              <Route path="/deciphering-the-language-of-love" element={<MainDLL />} />
            </Routes>
          </Router>
        </ContextProvider>
    </div >
  );
}

export default App;
