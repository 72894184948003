// eslint-disable-next-line import/no-anonymous-default-export
export default {
    links: {
        amazon: {
            paperback: "https://www.amazon.com.br/dp/B0CCL8XFNT",
            hardcover: "https://www.amazon.com.br/dp/B0CCL8XFNT"
        },
        whop: {
        },
        bookpreview: {
            dll: "https://www.africau.edu/images/default/sample.pdf#toolbar=0"
        }
    },
    hero: {
        title: "",
        copy: "Seeking genuine love? Wish to understand the mysteries surrounding relationships and yearn to unravel the secrets of love's magical mathematics? This book will be your definitive guide.",
        img: "../img/dll/bookpt.png",
    },
    carrousel: [
        {
            img: "book_mockup_en",
            button: "Buy here",
            h1: "",
            copy: "Seeking genuine love? Wish to understand the mysteries surrounding relationships and yearn to unravel the secrets of love's magical mathematics? This book will be your definitive guide.",
            h2: "",
            link: "/collections/deciphering-the-language-of-love?category=esoteric",
            preview: "Click for Sample",
            previewLink: "https://firebasestorage.googleapis.com/v0/b/image-provider-b2b36.appspot.com/o/politica%2FPoli%CC%81tica%20de%20Privacidade.pdf?alt=media&token=7153f364-be29-4823-9e19-778356331fbd&_gl=1*55yrzm*_ga*MTI5MDk5OTI5Mi4xNjg4NTU2MzQw*_ga_CW55HF8NVT*MTY5NzcwOTkxOC41MC4xLjE2OTc3MDk5NjQuMTQuMC4w",
        },
        {
            img: "cover_trology_map_discount_en",
            button: "Buy here",
            h1: "Unmissable Offer for Digital E-book Version",
            copy: "Numerological Trilogy + Calculator App and Personalized Map! Uncover the numbers with our Numerological Trilogy.",
            h2: "Purchase now and transform your life!",
            link: "/collections/deciphering-the-language-of-love_discount?category=esoteric",
            preview: "",
            previewLink: "",
        },
        {
            img: "map_en_l",
            button: "Buy here",
            h1: "Numerological Map",
            copy: "A Personalized Numerological Map that will reveal the secrets of your destiny and guide every step of your journey. Transform your life with exclusive insights. Reserve your Numerological Map now and take the first step towards a more conscious and fulfilling life.",
            h2: "",
            link: "/collections/numerological_map?category=esoteric",
            preview: "",
            previewLink: "",
        },
    ],
    testimony: {
        title: "Testimony",
        text1: {
            text: "After reading this book, I realized how numbers can really influence our relationships. It gave me a deep understanding and a new perspective on love. Highly recommended!",
            user: "J.C silva",
        },
        text2: {
            text: "This love numerology book is a treasure! A must-read for anyone in search of true connections.",
            user: "ana s.",
        },
        text3: {
            text: "As someone who has always been interested in the more spiritual side of love, this book was an incredible find. Now, I have valuable tools to understand and improve my relationships. A truly inspiring book!",
            user: "carlos m.",
        },
    },
    about: {
        h1: "Deciphering the Language of Love",
        h2: "Understanding Relationships Through Numbers",
        copy: "Enter this fascinating universe and decipher the unique language of love through numbers. Because when it comes to matters of the heart, each number tells a unique and precious story. This is your invitation to listen to these stories, to unravel the secrets of love, and find a deeper and more meaningful path in human relations. After all, love is the greatest story ever told, and numbers are the words that complete it.",
        bonus: {
            h1: "BONUS",
            h2: "Free",
            h3: "App and book Practical manual",
            copy: "By purchasing the book Deciphering the Language of Love in e-Book digital format, you receive the Practical Manual - The Power of Numerology: Uncovering the Secrets of Success + access to the Numerology Calculator App for free.",
        },
        formatos: {
            p: "Available formats:",
            arr: [
                {
                    formato: "E-book + Bonus",
                    link: "/collections/deciphering-the-language-of-love?category=esoteric"
                },
                {
                    formato: "Printed book",
                    link: "/collections"
                }
            ]
        }
    },
    otherBooks: [
        {
            title: "Recommendations",
            copytitle: "The Power of Numerology",
            subtitle: "Uncovering the Secrets of Success",
            copy: "Uncover the mysteries of numbers and elevate your evolution to the next level. Explore the power of numbers and transform your life.",
            button: {
                text: "Order now",
                link: "/collections/the-power-of-numerology?category=esoteric"
            },
            img: "poncoverpt",
        },
        {
            title: "Recommendations",
            copytitle: "Numerological Map",
            subtitle: "Personalized numerological map",
            copy: "Discover your personal numbers in an exclusive analysis with the author of the 'The Power of Numerology' trilogy. A completely personalized experience for you.",
            button: {
                text: "Order now",
                link: "/collections/numerological_map?category=esoteric"
            },
            img: "map_pt_s",
        },
    ],
    modal: {
        h1: "Unmissable Offer:",
        h2: "eBook - Numerological Trilogy + Calculator App + Personalized Map!",
        h3: "60% Discount",
        h4: "Uncover the numbers with our Numerological Trilogy, gain exclusive access to the Numerological Calculation App, and receive a Personalized Map to illuminate your path like never before.",
        h5: "Get it now and transform your life!",
        button: "BUY HERE",
        img: {
            1: "cover_pon_en",
            2: "cover_dll_en",
            3: "cover_pon_manual_en",
        }
    },
}

