import React from 'react'
import styled from 'styled-components'
import img from '../../img/img';

export default function BannerDinamic({image, title}) {
    return (
        <>
            <BannerDinamicSection className='hero-section'>
                <div className='background'>
                    <p className='background-text font-face-BodoniflfItalic'>{title}</p>
                </div>
            </BannerDinamicSection>
        </>
    )
}

const BannerDinamicSection = styled.section`
    position: relative;
    width: 100%;
    height: 60vh;
    .background {
        height: 100%;
        background-image: url(${img.banner_chair});
        background-size: cover;
        background-position: center 40%;
        filter: opacity(60%);
        display: flex;
        align-items: center;
        justify-content: center;
        .background-text{
            text-align: center;
            vertical-align: middle;
            color: #ffffff;
            font-size: 10rem;
            font-weight: bold;
            letter-spacing: 4px;
        }
    }
    @media screen and (min-width: 280px) and (max-width: 1079px) {
        /* border: 2px solid yellow; */
        height: 20vh;
        .background{
            .background-text{
                font-size: 4rem;
            }
        }
    }
`;