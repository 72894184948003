import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { myContext } from '../../../context/ContextProvider';
import imgDir from '../../../img/img';
import GoldButton from '../../common/GoldButton';
import { HiMiniStop } from "react-icons/hi2";
import { IoPlayOutline } from "react-icons/io5";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import languageDll from '../../../locales/books/dll/language-dll';


export default function Carrousel({ arr, h1class, h2class, copyclass }) {

    const { setShowBookPreview, setPdfLink, Language } = useContext(myContext);

    const [currentSlide, setCurrentSlide] = useState(0);
    const [autoPlay, setAutoPlay] = useState(true);

    const nextSlide = () => {
        // setCurrentSlide((prevSlide) => (prevSlide + 1) % img.prop.length);
        const res = (prevSlide) => (prevSlide + 1) % arr.length
        delay(res)
    };

    const prevSlide = () => {
        // setCurrentSlide((prevSlide) =>
        //     prevSlide === 0 ? img.prop.length - 1 : prevSlide - 1
        // );
        const res = (prevSlide) => prevSlide === 0 ? arr.length - 1 : prevSlide - 1
        delay(res);
    };

    const [carouselClass, setCarouselClass] = useState('carousel');

    const delay = (index) => {
        setCarouselClass('carousel carousel-transition');
        const timer = setTimeout(() => {
            setCurrentSlide(index)
            return () => clearTimeout(timer);
        }, 700);
    }

    useEffect(() => {
        let timer;
        if (autoPlay) {
            timer = setTimeout(() => {
                nextSlide();
            }, 10000); // Troca de slide a cada 10 segundos (ajuste conforme necessário)
        }

        // Limpe o timer ao desmontar o componente
        return () => clearTimeout(timer);
    }, [currentSlide, autoPlay]);

    useEffect(() => {
        // Adicione a classe 'carousel-transition' para aplicar a transição
        setCarouselClass('carousel carousel-transition');

        // Remova a classe 'carousel-transition' após um atraso de 1 segundo
        const timer = setTimeout(() => {
            setCarouselClass('carousel');
        }, 1500); // delay para que a imagem seja trocada quando nao estiver visivel

        // Limpe o timer ao desmontar o componente
        return () => clearTimeout(timer);
    }, [currentSlide]);

    // para a rolagem do carrossel
    const toggleAutoPlay = () => {
        setAutoPlay(!autoPlay);
    };

    const toggleBookPreview = (pdf_link) => {
        setPdfLink(pdf_link)
        setShowBookPreview(true)
    }

    return (
        <>
            <CarrouselComponent>
                {arr &&
                    <div className="content">
                        <div className={`container ${carouselClass}`}>
                            {
                                arr[currentSlide].img !== "" && (
                                    <div
                                        className="image-container"
                                    >
                                        {
                                            arr[currentSlide].linkPreview !== "" ? (
                                                <>
                                                    <img
                                                        onClick={() => toggleBookPreview(
                                                            arr[currentSlide].previewLink
                                                        )}
                                                        className='book carousel-image'
                                                        src={imgDir[arr[currentSlide].img]} alt="imageMock"
                                                    />

                                                    {
                                                        arr[currentSlide].preview !== ""
                                                            ?
                                                            <p> {arr[currentSlide].preview}</p>
                                                            :
                                                            null
                                                    }

                                                </>
                                            ) : (
                                                <img
                                                    className='book carousel-image'
                                                    src={imgDir[arr[currentSlide].img]} alt="imageMock"
                                                />
                                            )
                                        }
                                    </div>
                                )
                            }
                            <div className="copy">
                                <div className='copytext'>
                                    {
                                        arr[currentSlide].h1 !== "" &&
                                        <h1 className={`${h1class}`}>
                                            {arr[currentSlide].h1}
                                        </h1>
                                    }
                                    <p className={`font-face-ChauPhilomeneOne ${copyclass}`} >
                                        {arr[currentSlide].copy}
                                    </p>
                                    {
                                        arr[currentSlide].h2 !== "" &&
                                        <h2 className={`${h2class}`}>
                                            {arr[currentSlide].h2}
                                        </h2>
                                    }
                                </div>
                                {/* botao que envia para a pagina do produto */}
                                <GoldButton
                                    text={languageDll[Language].carrousel[currentSlide].button}
                                    link={languageDll[Language].carrousel[currentSlide].link}
                                />
                            </div>
                        </div>
                        {/* Carroussel buttons controll */}
                        <div className="carousel-footer">
                            <div className='buttons-container'>
                                <div
                                    className="button-next prev-button"
                                    onClick={prevSlide}
                                >
                                    {/* &#8249; */}
                                    <GrFormPrevious />
                                </div>
                                {arr.map((_, index) => (
                                    <div
                                        key={index}
                                        className={`carousel-dot ${currentSlide === index ? 'active' : ''}`}
                                        onClick={() => delay(index)}
                                    >
                                    </div>
                                ))}
                                <div
                                    className="button-next next-button"
                                    onClick={() => nextSlide()}
                                >
                                    {/* &#8250; */}
                                    <GrFormNext />
                                </div>
                            </div>

                            <div
                                className='button-play'
                                onClick={() => toggleAutoPlay()}
                            >
                                {
                                    autoPlay === false ?
                                        (<p className='react-icon'><IoPlayOutline /></p>)
                                        :
                                        (<p className='react-icon'><HiMiniStop /></p>)
                                }
                            </div>
                        </div>
                    </div>
                }
            </CarrouselComponent>
        </>
    )
}

const CarrouselComponent = styled.div`
.content {
    /* border: #000000 solid 1px; */
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    height: 100%;
    width: 100%;
    .title {
        color: #9f6e2d;
        height: 30%;
        font-size: 2rem;
        font-style: italic;
        font-weight: bold;
        text-shadow: rgb(255, 255, 255) 4px 3px 6px;
    }
    
    .container.carousel-transition {
        /* transform: translateX(100%); */
        animation: fadeOut ease 1.5s;
        /* transition: transform 1s ease-in-out; */
    }
    @keyframes fadeOut {
        0% {opacity: 1;}
        50% {opacity: 0;}
        100% {opacity: 1;}
    }
    .container {
        /* border: #000000 solid 4px; //border */
        height: 87%;
        max-width: 1080px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .image-container{
            /* border: #ff0004 solid 2px; //border */
            width: 372px;
            height: 450px;
            text-align: center;
            cursor: pointer;
            padding: 1rem;
            p{
                font-size: 1rem;
                font-weight: 900;
                text-align: center;
                color: #fff7e3;
                text-shadow: rgb(0, 0, 0) 4px 3px 6px;
                text-indent: 1em;
            }
            img {
                max-height: 450px;
                max-width: 372px;
                transition: 1s ease-in-out;
            }
            :hover{
                transform: scale(1.1);
                transition: 1s ease-in-out;
            }
        }
        .copy {
            /* border: #c300ff solid 2px; //border */
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 70%;
            height: 100%;
            .copytext {
                /* border: #0088ff solid 2px; //border */
                text-align: center;
                padding: 1rem;
                /* height: 90%; */
                h1{
                    color: #ead8c1;
                    text-transform: uppercase;
                    text-shadow: rgb(0, 0, 0) 4px 3px 6px,  rgb(204, 204, 204) -4px 3px 6px ;
                    font-size: 1.7em;
                    padding: 1rem;
                    letter-spacing: 3px;
                }
                p{
                    font-size: 1.6rem;
                    font-weight: 900;
                    text-align: center;
                    color: #fff7e3;
                    text-shadow: rgb(0, 0, 0) 4px 3px 6px;
                    text-indent: 1em;
                }
                h2{
                    margin-top: 2rem;
                    text-transform: uppercase;
                    font-size: 1.2rem;
                    font-weight: 900;
                    text-align: center;
                    color: #fff7e3;
                    text-shadow: rgb(0, 0, 0) 4px 3px 6px;
                    text-indent: 1em;
                    letter-spacing: 3px;
                }
            }
        }
    }
    .carousel-footer{
        /* border: #ff0000 solid 2px; */
        width: 200px;
        height: auto;
        /* border: #9f6e2d solid 2px; */
        display: flex;
        /* flex-direction: column; */
        align-items: center;
        justify-content: space-around;
        /* gap: 2rem; */
        .buttons-container{
            /* border: #000000 solid 2px; */
            display: flex;
            align-items: center;
            justify-content: space-around;
            gap: 2rem;
            .button-next{
                color: #000000;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                font-size: 2rem;
                color: #352f238b;
                cursor: pointer;
                text-align: center;
            }
            .carousel-dot{
                border: #000000 solid 1px;
                border-radius: 50%;
                width: 13px;
                height: 13px;
                cursor: pointer;
                background-color: #f0f0f0;
                -webkit-box-shadow: 5px 5px 15px 5px #FFFFFC; 
                box-shadow: 1px 1px 15px 4px #FFFFFC;
            }
            .active{
                background-color: #9f6e2d;
            }
        }
        .button-play{
            position: absolute;
            right: 2rem;
            cursor: pointer;
        }
    }
}
@media screen and (min-width: 280px) and (max-width: 1079px) {
    height: 100vh;
    .background{
    }
    .content{
        /* height: auto; */
        .container{
            width: auto;
            flex-direction: column;
            .image-container{
                /* height: 350px; */
                height: 40vh;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                    img{
                        height: auto;
                        max-width: 272px;
                    }
            }
            .copy{
                width: 100%;
                height: 50%;
                .copytext{
                    h1{
                        font-size: 1.4rem;
                        padding: 0rem;
                    }
                    p{
                        font-size: 1.4rem;
                    }
                    h2{
                        font-size: 1rem;
                        margin-top: 1rem;
                    }
                }
            }
        }
    }
} 
@media screen and (min-width: 280px) and (max-height: 622px) {
    .content{
        .container{
            /* height: 50%; */
            .image-container{
                /* height: 300px; */
                    img{
                        height: auto;
                        max-width: 232px;
                    }
            }
            .copy{
                /* width: 100%; */
                /* height: 50%; */
                .copytext{
                    /* padding: .5rem; */
                    h1{
                    }
                    p{
                        font-size: 1.2rem;
                    }
                    h2{
                        /* font-size: 1rem; */
                    }
                }
            }
        }
    }
} 
`;