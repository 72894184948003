import React, { useContext, useState } from "react";
import styled from "styled-components";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaQuestionCircle } from "react-icons/fa";
import { VscChromeClose } from "react-icons/vsc";
import { NavLink } from "react-router-dom";
import { BiHomeAlt } from "react-icons/bi"
import { MdOutlineCollectionsBookmark, MdOutlineAlternateEmail } from "react-icons/md"

import { myContext } from "../../context/ContextProvider";
import languageHome from "../../locales/home/language-home";
import LogoNav from "../home/LogoNav";

export default function Navbar() {

  const { Language } = useContext(myContext);
  const [navbarState, setNavbarState] = useState(false);
  const navbar = languageHome[Language].navbar

  const ICONS = {
    BiHomeAlt: <BiHomeAlt className="icon" />,
    MdOutlineCollectionsBookmark: <MdOutlineCollectionsBookmark className="icon" />,
    MdOutlineAlternateEmail: <MdOutlineAlternateEmail className="icon" />,
    FaQuestionCircle: <FaQuestionCircle className="icon" />,
    // ... adicione outros ícones conforme necessário
  };

  return (
    <>
      <NavComponent>
        <div className="brand">
          <div className="container">
            {/* <img src={img.home_logo_elipse_xl} alt="" /> */}
            <NavLink to="/" className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
              {/* <img src={img.home_logo_rectangle_xl} alt="" /> */}
              <LogoNav/>
            </NavLink>
          </div>
          <div className="toggle">
            {navbarState ? (
              <VscChromeClose onClick={() => setNavbarState(false)} />
            ) : (
              <GiHamburgerMenu onClick={() => setNavbarState(true)} />
            )}
          </div>
        </div>

        <ul>
          {navbar.linksArr.map((item, index) => (
            <li key={index} >
              <NavLink to={item.link} className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
                {ICONS[item.icon]}
                {item.text}
              </NavLink>
            </li>
          ))}
        </ul>
        {/* <button>Connect</button> */}
      </NavComponent>
      <ResponsiveNav $state={navbarState}>
        <ul>
          {navbar.linksArr.map((item, index) => (
            <li onClick={() => setNavbarState(false)} key={index} >
              <NavLink to={item.link} className={({ isActive }) => (isActive ? 'active' : 'inactive')} >
                {ICONS[item.icon]}
                {item.text}
              </NavLink>
            </li>
          ))}

        </ul>
      </ResponsiveNav>
    </>
  );
}

const NavComponent = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  .brand {
    /* border: #023e8a 1px solid; */
    width: 20%;
    .container {
      width: 200px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      .active{
        text-decoration: none;
        font-weight: bolder;
      }
      .inactive{
        text-decoration: none;
      }
      img{
        width: 60%;
        margin-left: 1rem;
      }
    }
    .toggle {
      display: none;
      font-size: 1.5rem;
      padding-right: 1rem;
    }
  }
  ul {
    width: 80%;
    /* border: #023e8a 1px solid; */
    display: flex;
    justify-content: space-around;
    gap: 1rem;
    list-style-type: none;
    li {
      .active{
        text-decoration: none;
        font-weight: bolder;
      }
      .inactive{
        text-decoration: none;
      }
      .icon{
        margin-right: 1rem;
      }
      a {
        text-decoration: none;
        color: #404040;
        font-size: 1.2rem;
        transition: 0.1s ease-in-out;
        &:hover {
          color: #023e8a;
        }
      }
      /* &:first-of-type {
        a {
          color: #023e8a;
          font-weight: 900;
        }
      } */
    }
  }
  button {
    padding: 0.5rem 1rem;
    cursor: pointer;
    border-radius: 1rem;
    border: none;
    color: white;
    background-color: #48cae4;
    font-size: 1.1rem;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    transition: 0.3s ease-in-out;
    &:hover {
      background-color: #023e8a;
    }
  }
  @media screen and (min-width: 280px) and (max-width: 1080px) {
    .brand {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .toggle {
        display: block;
      }
    }
    ul {
      display: none;
    }
    button {
      display: none;
    }
  }
`;

const ResponsiveNav = styled.div`
  border: #0077b6 solid 1px;
  display: flex;
  position: absolute;
  z-index: 1;
  top: ${({ $state }) => ($state ? "70px" : "-900px")};
  background-color: white;
  height: 30vh;
  width: 100%;
  align-items: center;
  transition: 0.3s ease-in-out;
  ul {
    list-style-type: none;
    width: 100%;
    li {
      width: 100%;
      margin: 1rem 0;
      margin-left: 2rem;
      .active{
        text-decoration: none;
        font-weight: bolder;
      }
      .inactive{
        text-decoration: none;
      }
      .icon{
        margin-right: 1rem;
      }
      a {
        text-decoration: none;
        color: #343434;
        font-size: 1.2rem;
        transition: 0.1s ease-in-out;
        padding: 1rem;
        &:hover {
          color: #023e8a;
        }
      }
      /* &:first-of-type {
        a {
          color: #023e8a;
          font-weight: 900;
        }
      } */
    }
  }
`;
