import React from 'react'
import styled from 'styled-components'

import { BiShoppingBag } from "react-icons/bi"

export default function BuyBTN({ text, link }) {

    // Dynamicly input event for pinterest
    const HandleEvent = () => {
        const script = document.createElement('script');
        script.innerHTML = `
            pintrk('track', 'checkout', {
            event_id: 'eventId0001',
            value: 100,
            order_quantity: 1,
            currency: 'USD'
            });
        `;
        document.body.appendChild(script);

        // Limpe o script quando o componente for desmontado
        return () => {
            document.body.removeChild(script);
        };
    }

    return (
        <>
            <GoldButtonComponent>
                <a className="nav-link" href={link} target="_blank" rel="noopener noreferrer" onClick={HandleEvent}>
                    <div className="gold-button">
                        <div className='icon-wraper'>
                            <BiShoppingBag className='icon' />
                        </div>
                        {text}
                    </div>
                </a>
            </GoldButtonComponent>
        </>
    )
}

const GoldButtonComponent = styled.div`
/* border: #444444 solid 2px; */
    
.nav-link{
    color: #5f5d5d;
    text-decoration: none;
    font-size: 1rem;
    display: flex;
    /* align-items: center; */
    /* justify-content: center; */
    .gold-button {
        min-width: 200px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 0;
        overflow: hidden;
        /* margin: 2rem; */
        width: auto;
        color: #373737;
        font-family: serif;
        font-size: 1rem;
        font-weight: 900;
        text-transform: uppercase;
        letter-spacing: 4px;
        white-space: nowrap;
        padding: 1rem;
        cursor: pointer;
        /* border-radius: 0.3rem; */
        border: 2px solid #AA8658;          
        /* background-color: white; */
        background: #FFFFFF;
        background: -moz-linear-gradient(top, #FFFFFF 0%, #FFFFFF 0%, #C59237 100%);
        background: -webkit-linear-gradient(top, #FFFFFF 0%, #FFFFFF 0%, #C59237 100%);
        background: linear-gradient(to bottom, #FFFFFF 0%, #FFFFFF 0%, #C59237 100%);
        transition: 0.3s ease-in-out;
        box-shadow: inset 0px 0px 7px 3px #fff, 0px 12px 29px -10px #AA8658, 0px 2px 50px 15px rgba(0,0,0,0);
        /* text-shadow: 2px 2px 0 #444444, -2px 2px 0 #cfcccc; */
        .icon-wraper{
            /* border: #373737 1px solid; */
            /* padding: .5rem; */
            margin-right: 1rem;
            .icon{
                /* border: #444444 solid 1px; */
                font-size: 1.7rem;
            }
        }
        
        &:after {
            /* border: 1px solid black; */
            content: "";
            position: absolute;
            top: -50%;
            right: -50%;
            bottom: -50%;
            left: -50%;
            background: -webkit-gradient(linear,left top,left bottom,from(rgba(229,172,142,0)),color-stop(50%,rgba(255,255,255,.7)),color-stop(50%,rgba(229,172,142,0)));
            background: -webkit-gradient(linear,left top,left bottom,from(rgba(229,172,142,0)),color-stop(50%,rgba(255,255,255,.7)),to(rgba(229,172,142,0)));
            background: -webkit-linear-gradient(top,rgba(229,172,142,0),rgba(255,255,255,.7) 50%,rgba(229,172,142,0));
            background: -webkit-gradient(linear,left top,left bottom,from(rgba(229,172,142,0)),color-stop(50%,rgba(255,255,255,.7)),to(rgba(229,172,142,0)));
            background: linear-gradient(to bottom,rgba(229,172,142,0),rgba(255,255,255,.7) 50%,rgba(229,172,142,0));
            -webkit-transform: rotateZ(60deg) ;
            -ms-transform: rotate(60deg) ;
            transform: rotateZ(60deg) ;
            -webkit-animation: glowing 3s infinite;
            animation: glowing 3s infinite;
            z-index: -1;
        }
            &:hover {
                background-color: #ffffff;
            }
            @keyframes glowing {
                0% { left: 900px; }
                50% { left:-400px; }
                100% { left:-1000px; }
            }
    }
}
    @media screen and (min-width: 280px) and (max-width: 1079px) {
        .gold-button {
            /* width: 10rem; */
            padding: 0.5rem;
            margin: .5rem;
            font-size: 1rem;
        }
    }
`;