import React, { useContext } from 'react'
import styled from 'styled-components'
import { myContext } from '../../../context/ContextProvider';
import img from '../../../img/img';
import { Link } from 'react-router-dom';

export default function Discount({ obj }) {
    const { Language } = useContext(myContext);
    
    
    return (
        <>
            <DiscountComponent>
                <div className='modal-container'>
                    <div className='copy'>
                        <h1 className='font-face-BodoniflfItalic'>
                            {obj[Language].modal.h1}
                        </h1>
                        <h2 className='font-face-prata'>
                            {obj[Language].modal.h2}
                        </h2>
                        <h3>
                            {obj[Language].modal.h3}
                        </h3>
                        <h4 className='font-face-GlacialIndifference'>
                            {obj[Language].modal.h4}
                        </h4>
                        <div className='book-mockup'>
                            <img src={img[obj[Language].modal.img[`1`]]} alt="" />
                            <img src={img[obj[Language].modal.img[`2`]]} alt="" />
                            <img src={img[obj[Language].modal.img[`3`]]} alt="" />
                        </div>
                        <h5 className='font-face-prata'>
                            {obj[Language].modal.h5}
                        </h5>
                    </div>
                    <Link to="/collections/deciphering-the-language-of-love_discount?category=esoteric" >
                        <button className='glow' type="button">
                            {obj[Language].modal.button}
                        </button>
                    </Link>
                </div>
            </DiscountComponent>
        </>
    )
}

const DiscountComponent = styled.div`
    /* position: fixed; */
margin-top: 10rem;
max-height: 800px;
max-width: 668px;
background-color: #fafafa;
box-shadow: 
inset -1px 3px 8px 5px #edeeef, 
0px 12px 29px -10px #AA8658,
0px 2px 50px 15px rgba(0,0,0,0);
border-radius: 10px;

.modal-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    background-image: url(${img.backgroundsale});
    
    .close{
        position: absolute;
        top: 10px;
        right: 10px;
        
        font-family: serif;
        padding: .5rem;
        cursor: pointer;
        /* border-radius: 0.3rem; */
        border: 2px solid #000000;          
        color: #000000;
        /* letter-spacing: 4px; */
        text-transform: uppercase;
        /* background-color: white; */
        background: #FFFFFF;
        
        font-size: 1.3rem;
        font-weight: 900;
        text-transform: uppercase;
        transition: 0.3s ease-in-out;
        box-shadow: inset 0px 0px 7px 3px #fff, 0px 12px 29px -10px #AA8658, 0px 2px 50px 15px rgba(0,0,0,0);
    }
    .copy{
        /* border: 1px solid black; */
        text-align: center;
        /* padding: 2rem; */
        h1{
            text-transform: uppercase;
            text-decoration: underline;
            font-size: 2rem;
            padding: 1rem;
        }
        h2{
            padding: 1rem;
            font-size: 1.1rem;
        }
        h3{
            text-transform: uppercase;
            font-size: 3rem;
            padding: 1rem;
            color: #d7462d;
        }
        h4{
            font-size: 1.2rem;
            padding: 1rem;
            letter-spacing: 2px;
            color: #402f13;
        }
        h5{
            /* border: 1px solid black; */
            text-transform: uppercase;
            font-size: 1rem;
            margin-top: 2rem;
            margin-bottom: 0;
            letter-spacing: 2px;
        }
        .book-mockup{
            width: 100%;
            height: auto;
            /* background-color: #c5913763;
            border-radius: 10px; */
            img{
                height: 200px;
                margin: 1rem;
            }
        }
    }
    
    .glow {
        position: relative;
        z-index: 0;
        margin: 1rem;
        /* margin-top: 2rem; */
        width: 14rem;
        font-family: serif;
        padding: 1rem;
        cursor: pointer;
        border: 2px solid #AA8658;          
        letter-spacing: 4px;
        text-transform: uppercase;
        color: #FFFFFF;
        text-shadow: 2px 2px 0 #444444;
        background: #FFFFFF;
        background: -moz-linear-gradient(top, #FFFFFF 0%, #FFFFFF 0%, #C59237 100%);
        background: -webkit-linear-gradient(top, #FFFFFF 0%, #FFFFFF 0%, #C59237 100%);
        background: linear-gradient(to bottom, #FFFFFF 0%, #FFFFFF 0%, #C59237 100%);
        font-size: 1.1rem;
        font-weight: 900;
        text-transform: uppercase;
        transition: 0.3s ease-in-out;
        box-shadow: inset 0px 0px 7px 3px #fff, 0px 12px 29px -10px #AA8658, 0px 2px 50px 15px rgba(0,0,0,0);
        @keyframes glowing {
            0% { background-position: 0 0; }
            50% { background-position: 400% 0; }
            100% { background-position: 0 0; }
        }
    }
}

@media screen and (min-width: 280px) and (max-width: 1079px) {
    margin-top: 5rem;
    margin-left: 1rem;
    margin-right: 1rem;
    height: 95vh;
    width: auto;
    .modal-container{
        .copy{
            h1{
                
                font-size: 1.5rem;
                padding: .5rem;
            }
            h2{
                padding: 1rem;
                font-size: 1.1rem;
            }
            h3{
                
                font-size: 2rem;
                padding: .5rem;
                
            }
            h4{
                font-size: 1.2rem;
                padding: .5rem;
            }
            h5{
                margin-top: 2rem;
                
                letter-spacing: 2px;
            }
            .book-mockup{
                img{
                    height: 100px;
                    margin: 1rem;
                }
            }
        }
    }
}
`;
