import React, { useContext } from 'react'
import styled from 'styled-components'
import { myContext } from '../../../context/ContextProvider';
import languageDll from '../../../locales/books/dll/language-dll';

export default function Testemony() {
    const { Language } = useContext(myContext);
    return (
        <>
            <TestemonySection className='testemony-section'>
                <h1 className='testemony-title font-face-prata'>
                    {languageDll[Language].testimony.title}
                </h1>
                <div className='line'></div>
                <div className='testemunhos'>
                    <div>
                        <p className='testemony-text font-face-GlacialIndifference'>
                            "{languageDll[Language].testimony.text1.text}"
                        </p>
                        <p className='owner font-face-GlacialIndifference '>
                            {languageDll[Language].testimony.text1.user}
                        </p>
                    </div>
                    <div>
                        <p className='testemony-text font-face-GlacialIndifference'>
                            "{languageDll[Language].testimony.text2.text}"
                        </p>
                        <p className='owner font-face-GlacialIndifference '>
                            {languageDll[Language].testimony.text2.user}
                        </p>
                    </div>
                    <div>
                        <p className='testemony-text font-face-GlacialIndifference'>
                            "{languageDll[Language].testimony.text3.text}"
                        </p>
                        <p className='owner font-face-GlacialIndifference '>
                            {languageDll[Language].testimony.text3.user}
                        </p>
                    </div>
                </div>
            </TestemonySection>
        </>
    )
}

const TestemonySection = styled.section`
    /* border: red 2px solid; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 8rem;
    margin-left: 1rem;
    margin-right: 1rem;
    .testemony-title {
        color: #AA8658;
        font-size: 1.4rem;
        text-transform: uppercase;
    }
    .line{
        background-color: #AA8658;
        height: 3px;
        width: 20vw;
    }
    .testemunhos {
        border-radius: 10px;
        background-color: #fafafa;
        height: auto;
        width: auto;
        display: flex;
        /* flex-direction: row; */
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin-top: 3rem;
        -webkit-box-shadow: inset -1px 3px 8px 5px #dbdbdb, 2px 5px 16px 0px #ebebeb, 0px 2px 50px 15px rgba(0,0,0,0); 
        box-shadow: inset -1px 3px 8px 5px #edeeef, 0px 12px 29px -10px #AA8658, 0px 2px 50px 15px rgba(0,0,0,0);
        
        div{
            height: 230px;
            width: 350px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            /* p{
            } */
            .testemony-text {
                color: #AA8658;
                text-align: center;
                padding: 1rem;
                padding-top: 2rem;
                
                /* font-size: 1.6rem; */
                /* padding: 6rem; */
            }
            .owner {
                color: #AA8658;
                text-align: center;
                text-transform: uppercase;
            }
        }
    }
    
`;
