import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom';
import { ImFacebook } from "react-icons/im"
import { AiOutlineInstagram } from "react-icons/ai"


export default function Footer({ footer }) {

    const OnFacebook = () => {
        window.open(`https://www.facebook.com/profile.php?id=61553146874737`, '_blank');
    };
    const OnInstagram = () => {
        window.open(`https://www.instagram.com/invites/contact/?i=1unt2xit92j63&utm_content=szzunz4`, '_blank');
    };

    return (
        <>
            <FooterComponent>
                <div className='footer-container'>
                    <div className='links'>
                        <ul>
                            {
                                footer.map((item, index) => (

                                    <li key={index}>
                                        <Link className="link" to={item.link} >
                                            {item.text}
                                        </Link>
                                    </li>

                                ))
                            }
                        </ul>
                        <div className='social'>
                            <Link
                                onClick={OnFacebook}
                                className='d-flex justify-content-center align-items-center fab m-1'
                                style={{ backgroundColor: '#3b5998' }}
                                to="">
                                <ImFacebook className='social-icon' style={{ color: '#ffffff' }} />
                            </Link>
                            <Link
                                onClick={OnInstagram}
                                className='d-flex justify-content-center align-items-center fab m-1'
                                style={{ backgroundColor: '#C13584' }}
                                to="">
                                <AiOutlineInstagram className='social-icon' style={{ color: '#ffffff' }} />
                            </Link>
                        </div>
                        <div className='copiright'>
                            @Copyright A&TTales 2023
                        </div>
                    </div>
                </div>
            </FooterComponent>
        </>
    )
}

const FooterComponent = styled.div`
    position: relative;
    bottom: 0;
    height: 500px;
    width: 100%;
    background-color: #464646;
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    margin-top: 15rem;

    .footer-container{
        position: relative;
        .links{
            /* border: #fff 1px solid; */
            height: 100%;
            ul{
                /* border: #fff 1px solid; */
                list-style-type: none;
                text-decoration: none;
                border-left: #fff 3px solid;
                padding: 2rem;
                margin-top: 3rem;
                li{
                    color: #fff;
                    padding: 10px;
                    .link{
                        color: #fff;
                        list-style-type: none;
                        text-decoration: none;
                    }
                }
            }
            .copiright{
                /* height: 100%; */
                /* border: #fff 1px solid; */
                color: #fff;
                position: absolute;
                bottom: 0;
                padding: 1rem;
                font-size: .8rem;
            }
            .social{
                /* border-top: #ffffff 1px solid; */
                /* border-bottom: #ffffff 1px solid; */
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 1rem;
                .fab {
                    padding: 10px;
                    width: 40px;
                    height: 40px;
                    text-align: center;
                    text-decoration: none;
                    border-radius: 50%;
                }
                .social-icon{
                    font-size: 2rem;
                }
            }
        }
    }

`;
