import React, { useContext } from 'react'
import styled from 'styled-components'
import Hero from './Hero'
import Testemony from './Testemony'


import Modal from './Modal'
import { myContext } from '../../../context/ContextProvider'
import PDFView from '../../common/PDFView'

import About from './About'
import languageDll from '../../../locales/books/dll/language-dll'

import Discount from './Discount'
import OtherBooks from './OtherBooks'
import Footer from '../../common/Footer'
import OtherItems from '../../common/OtherItems'
import languageHome from '../../../locales/home/language-home'
import Navbar from '../../common/NavBar'

export default function MainDLL() {
    const { Language, ShowModal, ShowBookPreview, pdfLink } = useContext(myContext);

    return (
        <>
            <Navbar navbar={languageHome[Language].navbar} />
            <DllContainer>
                <Hero />
                <Testemony />
                <About prop={languageDll[Language].about} />
                <Discount obj={languageDll} />
                <OtherBooks obj={languageDll[Language].otherBooks[0]} />
                <OtherBooks obj={languageDll[Language].otherBooks[1]} />
                <OtherItems obj={languageHome[Language].shopcart} />
                <Footer footer={languageHome[Language].footer} />
                {ShowModal && <Modal />}
                {ShowBookPreview && <PDFView prop={pdfLink} />}
                {/* <Offer obj={languageDll} /> */}
                {/* <Inscrevase /> */}
                {/* <ScreenSize/> */}
                {/* <Project/> */}
            </DllContainer>
        </>
    )
}

const DllContainer = styled.section`
    background-color: #EBE7E3;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    width: auto;
`;



