// eslint-disable-next-line import/no-anonymous-default-export
export default {
    links: {
        amazon: {
            paperback: "https://www.amazon.de/dp/B0CDNMNL44",
            hardcover: "https://www.amazon.de/dp/B0CDNGM48S"
        },
        whop: {
        },
        bookpreview: {
            dll: "https://firebasestorage.googleapis.com/v0/b/image-provider-b2b36.appspot.com/o/book_preview%2FDecifrando-o-amor-EN-Preview.pdf?alt=media&token=96d4767a-62cc-4b07-b79b-a6bf7ea35c58"
        }
    },
    hero: {
        title: "",
        copy: "Auf der Suche nach echter Liebe? Wollen Sie die Geheimnisse rund um Beziehungen verstehen und die Geheimnisse der magischen Mathematik der Liebe entschlüsseln? Dieses Buch wird Ihr definitiver Ratgeber sein.",
        img: "../img/dll/bookpt.png",
    },
    carrousel: [
        {
            img: "bookpt",
            button: "Hier kaufen",
            h1: "",
            copy: "Auf der Suche nach echter Liebe? Wollen Sie die Geheimnisse rund um Beziehungen verstehen und die Geheimnisse der magischen Mathematik der Liebe entschlüsseln? Dieses Buch wird Ihr definitiver Ratgeber sein.",
            h2: "",
            link: "/collections/deciphering-the-language-of-love?category=esoteric",
            preview: "Klicken Sie für eine Probe",
            previewLink: "https://firebasestorage.googleapis.com/v0/b/image-provider-b2b36.appspot.com/o/book_preview%2FDecifrando-o-amor-EN-Preview.pdf?alt=media&token=96d4767a-62cc-4b07-b79b-a6bf7ea35c58",
        },
        {
            img: "dll_discount_s_pt",
            button: "Hier kaufen",
            h1: "Unverzichtbares Angebot für die digitale E-Book-Version",
            copy: "Numerologische Trilogie + Rechner-App und personalisierte Karte! Entdecken Sie die Zahlen mit unserer numerologischen Trilogie.",
            h2: "Kaufen Sie jetzt und verwandeln Sie Ihr Leben!",
            link: "/collections/deciphering-the-language-of-love_discount?category=esoteric",
            preview: "",
            previewLink: "",
        },
        {
            img: "map_book_pt",
            button: "Buy here",
            h1: "Numerologische Karte",
            copy: "Eine personalisierte numerologische Karte, die die Geheimnisse Ihres Schicksals enthüllt und jeden Schritt Ihres Weges leitet. Verwandeln Sie Ihr Leben mit exklusiven Einblicken. Reservieren Sie jetzt Ihre numerologische Karte und machen Sie den ersten Schritt zu einem bewussteren und erfüllenden Leben.",
            h2: "",
            link: "/collections/numerological_map?category=esoteric",
            preview: "",
            previewLink: "",
        },
    ],
    testimony: {
        title: "Kundenmeinung",
        text1: {
            text: "Nachdem ich dieses Buch gelesen habe, habe ich erkannt, wie Zahlen wirklich unsere Beziehungen beeinflussen können. Es gab mir ein tiefes Verständnis und eine neue Perspektive auf die Liebe. Sehr zu empfehlen!",
            user: "J.C silva",
        },
        text2: {
            text: "Dieses Buch über Liebesnumerologie ist ein Schatz! Ein Muss für jeden, der nach wahren Verbindungen sucht.",
            user: "ana s.",
        },
        text3: {
            text: "Als jemand, der sich immer für die spirituellere Seite der Liebe interessiert hat, war dieses Buch ein unglaublicher Fund. Jetzt habe ich wertvolle Werkzeuge, um meine Beziehungen zu verstehen und zu verbessern. Ein wirklich inspirierendes Buch!",
            user: "carlos m.",
        },
    },
    about: {
        h1: "Die Sprache der Liebe entschlüsseln",
        h2: "Beziehungen durch Zahlen verstehen",
        copy: "Treten Sie in dieses faszinierende Universum ein und entschlüsseln Sie die einzigartige Sprache der Liebe durch Zahlen. Denn wenn es um Herzensangelegenheiten geht, erzählt jede Zahl eine einzigartige und kostbare Geschichte. Dies ist Ihre Einladung, diesen Geschichten zu lauschen, die Geheimnisse der Liebe zu lüften und einen tieferen und bedeutungsvolleren Weg in den menschlichen Beziehungen zu finden. Schließlich ist die Liebe die größte Geschichte, die je erzählt wurde, und Zahlen sind die Worte, die sie vervollständigen.",
        bonus: {
            h1: "BONUS",
            h2: "Gratis",
            h3: "App und Buch Praktisches Handbuch",
            copy: "Wenn Sie das Buch Die Sprache der Liebe entschlüsseln im digitalen E-Book-Format kaufen, erhalten Sie kostenlos das Praktische Handbuch - Die Macht der Numerologie: Die Geheimnisse des Erfolgs enthüllen + Zugang zur Numerologie-Rechner-App.",
        },
        formatos: {
            p: "Verfügbare Formate:",
            arr: [
                {
                    formato: "E-book + Bonus",
                    link: "/collections/deciphering-the-language-of-love?category=esoteric"
                },
                {
                    formato: "Gedrucktes Buch",
                    link: "/collections"
                }
            ]
        }
    },
    otherBooks: [
        {
            title: "Empfehlungen",
            copytitle: "Die Macht der Numerologie",
            subtitle: "Die Geheimnisse des Erfolgs enthüllen",
            copy: "Entdecken Sie die Geheimnisse der Zahlen und heben Sie Ihre Entwicklung auf die nächste Ebene. Erforschen Sie die Macht der Zahlen und verwandeln Sie Ihr Leben.",
            button: {
                text: "Jetzt bestellen",
                link: "https://www.thepowerofnumerology.com"
            },
            img: "poncoverpt",
        },
        {
            title: "Empfehlungen",
            copytitle: "Numerologische Karte",
            subtitle: "Personalisierte numerologische Karte",
            copy: "Entdecken Sie Ihre persönlichen Zahlen in einer exklusiven Analyse mit dem Autor der Trilogie Die Macht der Numerologie. Ein völlig persönliches Erlebnis für Sie.",
            button: {
                text: "Jetzt bestellen",
                link: "/collections/numerological_map?category=esoteric"
            },
            img: "map_pt_s",
        },
    ],
    modal: {
        h1: "Unverzichtbares Angebot:",
        h2: "eBook - Numerologische Trilogie + Rechner-App + personalisierte Karte!",
        h3: "60% Rabatt",
        h4: "Entdecken Sie die Zahlen mit unserer numerologischen Trilogie, erhalten Sie exklusiven Zugang zur numerologischen Berechnungs-App und erhalten Sie eine personalisierte Karte, um Ihren Weg wie nie zuvor zu beleuchten.",
        h5: "Holen Sie es sich jetzt und verwandeln Sie Ihr Leben!",
        button: "HIER KAUFEN",
        img: {
            1: "manualcoverpt",
            2: "bookpt",
            3: "poncoverpt",
        }
    },
    shopcart: {
        otherItems: {
            title: "EMPFOHLENE ARTIKEL",
            arr: [
                {
                    title: "Unverzichtbares Angebot",
                    item: "Numerologische Trilogie + Numerologische Karte + App",
                    format: "E-Book - Download",
                    img: "dll_discount_pt",
                    price: "$ 102,97",
                    link: "",
                },
                {
                    title: "",
                    item: "PERSONALISIERTE Numerologische Karte",
                    format: "E-Book - Download",
                    img: "map_pt_s",
                    price: "220,00 €",
                    link: "",
                },
                {
                    title: "",
                    item: "Die Macht der Numerologie - Die Geheimnisse des Erfolgs enthüllen",
                    format: "E-Book - Download",
                    img: "pon_pt_s",
                    price: "14,99 €",
                    link: "",
                },
                {
                    title: "",
                    item: "DIE SPRACHE DER LIEBE ENTSCHLÜSSELN + BONUS",
                    format: "E-Book - Download",
                    img: "dllpt",
                    price: "14,99 €",
                    link: "",
                },
            ],
        },
    },
}

