import React from 'react';
import styled from 'styled-components';
import img from '../../img/img';

const SslSecureBadge = () => {
  return (
    <BadgeContainer>
      <img
        src={img.stamp}
        alt="SSL Secure Badge"
      />
    </BadgeContainer>
  );
};

const BadgeContainer = styled.div`
  position: absolute;
  top: 40px; /* Ajuste a posição vertical conforme necessário */
  right: 10px; /* Ajuste a posição horizontal conforme necessário */
  z-index: 9999; /* Certifique-se de que o badge esteja acima de outros elementos */
  background-color: transparent;
  img{
    width: 100px;
  }
  @media screen and (min-width: 280px) and (max-width: 720px) {    
      top: 40px;
  }
`;

export default SslSecureBadge;

