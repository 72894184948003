import React, { useContext } from 'react'
import styled from 'styled-components'
import Footer from '../common/Footer';
import languageHome from '../../locales/home/language-home';
import { myContext } from '../../context/ContextProvider';
import Navbar from '../common/NavBar';

export default function Impressum() {
    const { Language } = useContext(myContext);

    return (
        <>
        <Navbar/>
            <ImpressumStyle>
                <div className='impressum-wrapper'>
                    <p className='font-face-poppins-regular display-6 mt-5'>
                        Impressum
                    </p>
                    <p>
                        Tiago Frederico Kümmecke
                    </p>
                    <p>
                        Knevelspfädchen 36
                    </p>
                    <p>
                        47249 Duisburg
                    </p>
                    <p>
                        Deutschland
                    </p>
                    <p>
                        Telefon: 0174/3796030
                    </p>
                    <p>
                        E-Mail: at.comercial.business@gmail.com
                    </p>
                    <p>
                        Inhaber: Tiago Frederico Kümmecke
                    </p>
                    <p>
                        USt-IdNr.:DE361748732
                    </p>
                    <p>
                        Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie hier finden https://ec.europa.eu/consumers/odr/.
                    </p>
                    <p>
                        Verbraucherstreitbeilegung/Universalschlichtungsstelle: Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
                    </p>
                </div>
            </ImpressumStyle>
            <Footer footer={languageHome[Language].footer} />
        </>
    )
}

const ImpressumStyle = styled.div`
display: flex;
align-items: center;
justify-content: center;
.impressum-wrapper{
    max-width: 600px;
}
`;
