import React, { useContext } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom';
import { ImFacebook } from "react-icons/im"
import { RiTwitterXFill } from "react-icons/ri"
import { BiLogoPinterestAlt, BiLogoTelegram } from "react-icons/bi"
import languageHome from '../../locales/home/language-home';
import { myContext } from '../../context/ContextProvider';
// import { AiOutlineInstagram, AiOutlineWhatsApp } from "react-icons/ai"

export default function SocialSharing() {
    const { Language } = useContext(myContext);

    const url = "http://localhost:3000"; // Substitua pela URL do livro que deseja compartilhar

    const shareOnFacebook = () => {
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`, '_blank');
    };

    const shareOnTwitter = () => {
        window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}`, '_blank');
    };

    const shareOnPinterest = () => {
        window.open(`https://pinterest.com/pin/create/button/?url=${encodeURIComponent(url)}`, '_blank');
    };    

    const shareOnTelegram = () => {
        window.open(`https://telegram.me/share/url?url=${encodeURIComponent(url)}`, '_blank');
    };    

    return (
        <SocialComponent >

            <div className="text-center text-white">
                <div className="text-center text-dark p-3 " >
                    {languageHome[Language].collection.sicialshare}
                </div>

                <div className="container p-2 pb-0">

                    <section className="d-flex justify-content-center align-items-center">
                        <Link
                            onClick={shareOnFacebook}
                            className='d-flex justify-content-center align-items-center fab m-1'
                            style={{ backgroundColor: '#3b5998' }}
                            to="">
                            <ImFacebook className='social-icon' style={{ color: '#ffffff' }} />
                        </Link>
                        <Link
                                onClick={shareOnPinterest}
                                className='d-flex justify-content-center align-items-center fab m-1'
                                style={{ backgroundColor: '#c61118' }}
                                to="">
                            <BiLogoPinterestAlt className='social-icon' style={{ color: '#ffffff' }} />
                        </Link>
                        <Link
                                onClick={shareOnTelegram}
                                className='d-flex justify-content-center align-items-center fab m-1'
                                style={{ backgroundColor: '#229ED9' }}
                                to="">
                            <BiLogoTelegram className='social-icon' style={{ color: '#ffffff' }} />
                        </Link>
                        <Link
                                onClick={shareOnTwitter}
                            className='d-flex justify-content-center align-items-center fab m-1'
                            style={{ backgroundColor: '#e6e6e6' }}
                            to="">
                            <RiTwitterXFill className='social-icon' style={{ color: '#000000' }} />
                        </Link>
                    </section>
                </div>
            </div>
        </SocialComponent>
    )
}

const SocialComponent = styled.div`
    .fab {
        padding: 10px;
        width: 40px;
        height: 40px;
        text-align: center;
        text-decoration: none;
        border-radius: 50%;
    }
    .social-icon{
        font-size: 2rem;
    }
    
`;
