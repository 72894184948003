import React, { useContext } from 'react'
import styled from 'styled-components';
import { myContext } from '../../context/ContextProvider';
import { IoCloseSharp } from "react-icons/io5";

export default function PDFView(pdf_link) {
    
    const { setShowBookPreview } = useContext(myContext);

    return (
        <>
            <PdfViewContainer>
                <PDFViewComponent>
                    <div className='pdf-section'>
                        <div className='close' onClick={() => setShowBookPreview(false)}>
                            <IoCloseSharp />
                        </div>

                        <iframe
                            className='pdf-book'
                            type='application/pdf'
                            src={`${pdf_link.prop}?#toolbar=0&navpanes=0`}
                            frameBorder="0"
                            title='preview book'
                        />
                    </div>
                </PDFViewComponent>
            </PdfViewContainer>
        </>
    )
}

const PdfViewContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const PDFViewComponent = styled.section`
        /* border: red 1px solid; */
        position: absolute;
        margin: 1rem;
        background-color: #ffffff78;
        /* filter: blur(10px); */
        border-radius: 10px;
        top: 0;
        z-index: 9999;
    .pdf-section{
        border: '1px solid rgba(0, 0, 0, 0.3)';
        height: '750px';
        .close{
            border-radius: 10px;
            font-size: 2rem;
            text-align: end;
            cursor: pointer;
            padding: .5rem;
        }
        .pdf-book{
            width: 40vw;
            height: 90vh;
        }
    }
    @media screen and (min-width: 280px) and (max-width: 1079px) {
        .pdf-section{
            .pdf-book{
                width: 95vw;
            }
        }
    }
`;