import React, { useContext, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import languageHome from '../../locales/home/language-home';
import Navbar from './NavBar';
import { myContext } from '../../context/ContextProvider';
import Footer from './Footer';

const ContactPage = () => {
    const { Language } = useContext(myContext);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });
    const [status, setStatus] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setStatus('Enviando...');
        try {
            const response = await axios.post('https://atcomercial-backend.vercel.app/api/contact', formData);
            setStatus(response.data.message);
            setFormData({ name: '', email: '', subject: '', message: '' }); // Limpa o formulário
        } catch (error) {
            setStatus(languageHome[Language].contact.error);
        }
    };

    return (
        <>
            <Navbar navbar={languageHome[Language].navbar} />
            <ContactContainer>
                <ContactHeader>{languageHome[Language].contact.title}</ContactHeader>
                <ContactSubHeader>
                    {languageHome[Language].contact.text}
                </ContactSubHeader>
                <ContactSubHeader>
                    {languageHome[Language].contact.text2}
                </ContactSubHeader>
                <ContactSubHeader>
                    {languageHome[Language].contact.text3}
                </ContactSubHeader>
                <ContactSubHeader>
                    {languageHome[Language].contact.text4}
                </ContactSubHeader>
                <StyledForm onSubmit={handleSubmit}>
                    <div className='form-area'>
                        <label>{languageHome[Language].contact.label2}</label>
                        <StyledInput type="text" name="name" value={formData.name} onChange={handleChange} required />
                    </div>
                    <div className='form-area'>
                        <label>{languageHome[Language].contact.label}</label>
                        <StyledInput type="email" name="email" value={formData.email} onChange={handleChange} required />
                    </div>
                    <div className='form-area'>
                        <label>{languageHome[Language].contact.label3}</label>
                        <StyledInput type="text" name="subject" value={formData.subject} onChange={handleChange} />
                    </div>
                    <div className='form-area'>
                        <label>{languageHome[Language].contact.label4}</label>
                        <StyledTextArea name="message" value={formData.message} onChange={handleChange} required />
                    </div>
                    <div className='form-area'>
                        <StyledButton type="submit">{languageHome[Language].contact.label5}</StyledButton>
                    </div>
                </StyledForm>
                <StatusMessage>{status}</StatusMessage>
            </ContactContainer>
            <Footer footer={languageHome[Language].footer} />
        </>
    );
}

export default ContactPage;

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 2rem;
  `;

const ContactHeader = styled.h2`
  color: #333;
  margin-bottom: 0.5rem;
  `;

const ContactSubHeader = styled.p`
  color: #666;
  margin-bottom: 2rem;
  text-align: justify;
  width: 50%;
  @media screen and (max-width: 920px) {
      width: 100%;
  }
`;

const StatusMessage = styled.p`
  color: #007bff;
  margin-top: 1rem;
`;

const StyledForm = styled.form`
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        background-color: #f4f4f4;
        border-radius: 10px;
        max-width: 400px;
        margin: 20px auto;
        .form-area{
            width: 100%;
        }
    `;

const StyledInput = styled.input`
        padding: 10px;
        margin: 10px 0;
        width: 100%;
        border-radius: 5px;
        border: 1px solid #ccc;
    `;

const StyledTextArea = styled.textarea`
        padding: 10px;
        margin: 10px 0;
        width: 100%;
        height: 100px;
        border-radius: 5px;
        border: 1px solid #ccc;
    `;

const StyledButton = styled.button`
        padding: 10px 20px;
        background-color: #007BFF;
        color: #fff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;
    
        &:hover {
            background-color: #0056b3;
        }
`;