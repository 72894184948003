import styled from 'styled-components';
import { Card, Button } from 'react-bootstrap';
import img from '../../img/img';
import { Link } from 'react-router-dom';
import {AiOutlineShoppingCart} from "react-icons/ai"

const BookCard = ({ book, ...props }) => (
    <StyledCard>
        <Link to={book.link}>
            <Card.Img variant="top" src={img[book.image]} />
        </Link>
        <Card.Body className="d-flex flex-column justify-content-between">
            <Card.Title className='text-card-title font-face-poppins-bold'>{book.title}</Card.Title>
            <Card.Text className='text-card'>{book.author}</Card.Text>
            <Card.Text className='text-card'>{book.version}</Card.Text>
            <Link to={book.link}>
                <StyledButton variant="primary">
                <AiOutlineShoppingCart/>{book.price}
                </StyledButton>
            </Link>
        </Card.Body>
    </StyledCard>
);

export default BookCard;

// const StyledCard = styled(Card)`
//   width: 18rem; // ou a largura desejada
//   margin: 0.5em; // para ter um pequeno espaço entre os cartões
// `;
const StyledCard = styled(Card)`
    max-width: 12rem; // Máximo de 18rem
    min-width: 10rem; // Mínimo de 10rem
    margin: 0.5em;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); // Adiciona sombra (opcional)
    flex-direction: column;
    .text-card-title{
    display: -webkit-box;
    -webkit-line-clamp: 2; // Limita o texto a duas linhas
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1rem;
    height: 2.5em; // Altura baseada no tamanho da fonte e no número de linhas
    }
    .text-card{
        font-size: .9rem;
    }
    
    @media (max-width: 768px) {
        max-width: 40vw; // Em telas menores, use uma porcentagem da largura da tela
    }
`;
const StyledButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: space-between; // Isso alinha o ícone e o preço nas extremidades opostas do botão
    width: 100%; // Isso faz com que o botão ocupe toda a largura do container do card
    .shopping-cart-icon {
        margin-right: auto; // Isso garante que o ícone fique à esquerda
    }
`;