import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export default function ErrorPage() {
    return (
        <>
            <ErrorComponent>
                <p>
                    404, Page not found.
                </p>
                <p>
                    go back home.
                </p>
                <Link to="/" >Home</Link>
            </ErrorComponent>
        </>
    )
}

const ErrorComponent = styled.div`
width: 100vw;
height: 100vh;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
font-size: 3rem;
p{
    padding: 1rem;
}
`;