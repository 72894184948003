// eslint-disable-next-line import/no-anonymous-default-export
export default {
    navbar: {
        logo: "",
        linksArr: [
            {
                text: "Home",
                link: "/",
                icon: "BiHomeAlt",
            },
            {
                text: "Collections",
                link: "/collections",
                icon: "MdOutlineCollectionsBookmark",
            },
            {
                text: "Contact",
                link: "/contact",
                icon: "MdOutlineAlternateEmail",
            },
            {
                text: "FAQ",
                link: "/faq",
                icon: "FaQuestionCircle",
            },
        ]
    },
    home: {
        welcome: {
            text1: "Welcome to",
            text2: "On each page, a new horizon. Begin unforgettable journeys."
        },
        button: {
            text: "Collections",
            link: "/collections",
        }
    },
    newsletter:{
        title: "Subscribe to our Newsletter",
        email: "Enter your email",
        button: "Subscribe",
        manage: "To manage your subscription click",
        here: "manage",
        unsub: "Unsubscribe from our Newsletter",
        emailunsub:"Enter your email to unsubscribe",
        buttonunsub: "Unsubscribe"
    },
    contact: {
        title: "Contact us!",
        text: "We at A&T Tales are always ready to hear from you. Whether it's a question about our books, a question about your order or even feedback about your reading, your voice is valuable to us.",
        text2: "Fill out the form below or email us directly at info@aettales.com. We promise to respond as quickly as possible with all the attention you deserve.",
        text3: "For support or after-sales questions, please get in touch at contact@aettales.com.",
        text4: "Thank you for choosing A&T Tales, where each story is a new journey!",
        label: "Email: ",
        label2: "Name: ",
        label3: "Subject: ",
        label4: "Message: ",
        label5: "Send",
        email: "info@aettales.com",
        error: 'Error sending the message. Try again later.',
        aftersales: {
            label2: "Birth name: ",
            label3: "Date of birth: ",
            title: "Thank you for your purchase!",
            text: "Dear reader, ",
            text2: "We are delighted to confirm the receipt of your order! We are thrilled that you have chosen A&T Tales to accompany you on the next pages of adventure and knowledge.",
            text3: "The next step to receive your numerological map is to send us your information. Please fill out the form below with your birth name, date of birth, and the email you registered with the purchase. Once we receive your data, we will begin working on creating your personalized map. The estimated time for completion is 3 business days. We will send an email with a link to download the file.",
            text4: "Any questions?",
            text5: "We are here to help. If you have any questions or need assistance, please feel free to contact us via the contact link on the page.",
            text6: "Enjoy the reading, and may it be just the beginning of a long story between you and A&T Tales.",
            text7: "With appreciation,",
            text8: "The A&T Tales Team",
            text9: "Attention",
            alert: "Por favor, preencha todos os campos."
        }
    },
    faq: {
        title: "FAQ - Frequently Asked Questions",
        arr: [
            {
                question: "How can I purchase a book?",
                answer: "You can purchase our books directly on our website. Choose the book you want, add it to the cart, and proceed to checkout to complete your purchase."
            },
            {
                question: "What payment methods are accepted?",
                answer: "We accept various payment methods, including credit card, PayPal, and Pix. Select the preferred method at the time of payment."
            },
            {
                question: "In what formats are the books available?",
                answer: "Our books are available in hardcover, paperback, and digital (eBook) formats. On each book's page, you will find the available format options."
            },
            {
                question: "How can I access the eBook after purchase?",
                answer: "After the purchase, you can instantly download the eBook using the provided link on the page, and it will be available in your Whop account."
            },
            {
                question: "Is it safe to buy on the site?",
                answer: "Our payment provider Whop and Amazon use SSL encryption technology to protect your personal and financial data. Your security is our priority."
            },
            {
                question: "Can I return a book if I am not satisfied?",
                answer: "For physical books, please contact Amazon.com directly where you made the purchase. For eBooks, contact us through the contact form link or by email at info@aettales.com, and request a refund within 14 days after the purchase."
            },
            {
                question: "How can I contact you if I have other questions?",
                answer: "If you have further questions, you can contact us through our contact page or by email at info@aettales.com. Our team is ready to assist you!"
            },
        ]
    },
    carousel: [
        // {
        //     image: "banner_trology_discount_1500_en",
        //     imagemob: "dll_discount_pt",
        //     text: "Uncover the mysteries of numbers and elevate your evolution to the next level. Explore the power of numbers and transform your life.",
        //     title: "The Power of Numerology",
        //     link: "/collections/numerology-trilogy?category=esoteric"
        // },
        // {
        //     image: "banner_trology_discount_map_1500_pt",
        //     imagemob: "cover_trology_discount_pt",
        //     text: "Uncover the mysteries of numbers and elevate your evolution to the next level. Explore the power of numbers and transform your life.",
        //     title: "The Power of Numerology",
        //     link: "/collections/deciphering-the-language-of-love_discount?category=esoteric"
        // },
        {
            image: "banner_echoes_en",
            imagemob: "banner_echoes_en",
            text: "",
            title: "Echoes of 3077",
            link: "/collections/echoes-of-3077?category=science-fiction"
        },
        {
            image: "banner_dll_1500_en",
            imagemob: "banner_dll_mobile_pt",
            text: "Uncover the mysteries of numbers and elevate your evolution to the next level. Explore the power of numbers and transform your life.",
            title: "Deciphering the Language of Love",
            link: "/deciphering-the-language-of-love"
        },
        {
            image: "banner_pon_1500_en",
            imagemob: "banner_pon_mobile_pt",
            text: "Uncover the mysteries of numbers and elevate your evolution to the next level. Explore the power of numbers and transform your life.",
            title: "The Power of Numerology",
            link: "/collections/the-power-of-numerology?category=esoteric"
        },
    ],
    shopcart: {
        button: "Buy",
        otherItems: {
            title: "RECOMMENDED eBooks",
            arr: [
                {
                    title: "",
                    item: "Echoes of 3077 - The Awakening - Part I",
                    format: "E-Book - Download",
                    img: "cover_echoes_en",
                    price: "$ 14,99",
                    link: "/collections/echoes-of-3077?category=science-fiction",
                },
                // {
                //     title: "",
                //     item: "Numerological trilogy + personalized numerological map + app",
                //     format: "E-Book - Download",
                //     img: "cover_trology_map_discount_en",
                //     price: "$ 102,97",
                //     link: "/collections/deciphering-the-language-of-love_discount?category=esoteric",
                // },
                // {
                //     title: "",
                //     item: "Numerological trilogy + app",
                //     format: "E-Book - Download",
                //     img: "cover_trology_discount_en",
                //     price: "$ 25,97",
                //     link: "/collections/numerology-trilogy?category=esoteric",
                // },
                // {
                //     title: "",
                //     item: "PERSONALIZED numerological map",
                //     format: "E-Book - Download",
                //     img: "map_en_s",
                //     price: "$ 220,00",
                //     link: "/collections/numerological_map?category=esoteric",
                // },
                {
                    title: "",
                    item: "The power of numerology - uncovering the secrets of success",
                    format: "E-Book - Download",
                    img: "cover_pon_en",
                    price: "$ 14,99",
                    link: "/collections/the-power-of-numerology?category=esoteric",
                },
                {
                    title: "",
                    item: "DECIPHERING THE LANGUAGE OF LOVE + BONUS",
                    format: "E-Book - Download",
                    img: "cover_dll_en",
                    price: "$ 14,99",
                    link: "/collections/deciphering-the-language-of-love?category=esoteric",
                }
            ],
        },
    },
    collection: {
        section_title: "Collections",
        add: "Add to Cart",
        available: "Safe buy | SSL encryption | Whop service provider",
        recomend: "Recommend",
        showmore: "show more",
        example: "reading example",
        pages: "Number of pages",
        language: "Language",
        editor: "Editor",
        isbn: "ISBN-13",
        more: "More of ",
        bradcrumb: "eBooks",
        description: "Description",
        details: "Details",
        preview_image: "CLICK FOR PREVIEW",
        sicialshare: "Share",
        collection_title: {
            esoteric: "Esoteric eBooks",
            scifi: "Science Fiction"
        },
        biblio: {
            pages: "Pages",
            language: "Language",
            version: "version",
            publisher: "Publisher",
            isbn: "ISBN",
        },
        info: {
            info: "Information about e-books",
            infoText: "Our e-books are carefully crafted to provide you with an engaging and accessible reading experience. To ensure that you make the most out of your digital library, here are some important details:",
            format: "File Format: EPUB",
            formatText: "All of our e-books are available in the EPUB file format. This means that you can read them on a wide variety of devices, including your computer, tablet, smartphone, or e-reader. The simplicity of the EPUB format allows you to enjoy your reading anywhere and at any time, without the need for specialized software.",
            detail: "Format Details and Protection",
            detailText: "In the detailed view of each title, you will find specific information about the e-book's format and any copy protection, where applicable. Our priority is to ensure that you have all the necessary information to enjoy your reading without any complications.",
            transfer: "Easy Transfer to Your Device",
            transferText: "Transferring our e-books to your device is straightforward. E-books without copy protection or with a digital watermark can be easily transferred to your device, ensuring a smooth reading experience. Be sure to check compatibility with your device for a seamless transition."
        }
    },
    footer: [
        {
            link: "/contact",
            text: "Contact",
        },
        {
            link: "/privacy",
            text: "Privacy and Terms of Use",
        },
        {
            text: "FAQ",
            link: "/faq"
        },
        {
            text: "Newsletter",
            link: "/newsletter"
        }
    ],
    terms: {
        text: "This website uses Google Analytics to collect analysis data in order to improve the user experience. This information is collected anonymously and includes data such as geographic location, device used, pages visited, and time spent on the site. By continuing to browse this site, you agree to the collection and processing of these data by Google Analytics. For more information on how Google Analytics collects and processes data, please see our Privacy Policy.",
        title: "Privacy and Terms of Use",
        link: "https://firebasestorage.googleapis.com/v0/b/image-provider-b2b36.appspot.com/o/politica%2FPoli%CC%81tica%20de%20Privacidade.pdf?alt=media&token=7153f364-be29-4823-9e19-778356331fbd&_gl=1*55yrzm*_ga*MTI5MDk5OTI5Mi4xNjg4NTU2MzQw*_ga_CW55HF8NVT*MTY5NzcwOTkxOC41MC4xLjE2OTc3MDk5NjQuMTQuMC4w",
    },
    compliance: {
        termsOfServiceTitle: "Terms of Service",
        termsOfServiceText: "By using this app, you agree to our terms of service.",
        privacyPolicyTitle: "Privacy Policy",
        privacyPolicyText: "We are committed to protecting your privacy. Read our privacy policy for more information."
    },
}