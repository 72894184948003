import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import './fonts/Alice/Alice-Regular.ttf'
import './fonts/Prata/Prata-Regular.ttf'
import './fonts/Shrikhand/Shrikhand-Regular.ttf'
import './fonts/Chau_Philomene_One/ChauPhilomeneOne-Regular.ttf'
import './fonts/glacial-indifference/GlacialIndifference-Regular.otf'
import './fonts/bodoniflf-font/BodoniflfBolditalic-K7dD.ttf'
import './fonts/bodoniflf-font/BodoniflfItalic-2OEw.ttf'
import './fonts/kollektif/Kollektif-BoldItalic.ttf'
import "./fonts/Lora/Lora-Italic-VariableFont_wght.ttf"
import "./fonts/Quattrocento/Quattrocento-Regular.ttf"
import "./fonts/TheSeasons/Fontspring-DEMO-theseasons-it.otf"
import "./fonts/Alice/Alice-Regular.ttf"
import "./fonts/Allura/Allura-Regular.ttf"
import "./fonts/Poppins/Poppins-Regular.ttf"
import "./fonts/Poppins/Poppins-Bold.ttf"


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

